import { Tooltip } from '@mui/material';

const CustomBar = ({ value }) => {
    const barStyle = {
      width: '100%',
      height: '13px',
      borderRadius: '6px',
      background: 'linear-gradient(to right, #294AAF, #4DDA8F, #f5d452, #DD862A, #C92914)',
      position: 'relative',
      overflow: 'hidden',
    };
  
    const markerStyle = {
      position: 'absolute',
      top: '50%',
      left: `${value * 100}%`,
      transform: 'translate(-50%, -50%)',
      width: '3px',
      borderRadius: '3px',
      height: '10px',
      backgroundColor: 'white',
      zIndex: 1,
    };
  
    const disabledOverlayStyle = {
      position: 'absolute',
      top: 0,
      left: `${value * 100}%`,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(200, 200, 200, 0.7)',
      zIndex: 0,
    };
  
    const tickStyle = {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '6px',
      backgroundColor: '#8c8c8c',
    };
  
    const ticks = Array.from({ length: 9 }, (_, index) => (
      <div
        key={index}
        style={{
          ...tickStyle,
          left: `${((index + 1) / 10) * 100}%`,
        }}
      />
    ));
  
    return (
      <Tooltip title={`${value.toFixed(3)}`} placement="top" arrow>
        <div style={barStyle}>
          {ticks}
          <div style={markerStyle} />
          <div style={disabledOverlayStyle} />
        </div>
      </Tooltip>
    );
  };


  export default CustomBar;