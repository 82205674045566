// LeftSidebar/reducers.js
import {
  UPDATE_CHECKED,
  UPDATE_IS_CHECKED,
  UPDATE_SLIDER_VALUE,
  UPDATE_AI_TEXT,
} from './actions';

const initialState = {
  checked: false,
  isChecked: {
    lungs: false,
    abdomen: false,
    ribcage: false,
    thoracicSpine: false,
    diaphragm: false,
    softTissues: false,
    pleura: false,
    selectAll: false,
  },
  sliderValue: 50,
  aiText: '',
};

const leftSidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CHECKED:
      console.log('UPDATE_CHECKED', action.payload);
      return {
        ...state,
        checked: action.payload,
      };
      case UPDATE_IS_CHECKED:
        if (action.payload.selectAll !== undefined) {
          const newChecked = action.payload.selectAll;
          return {
            ...state,
            isChecked: {
              ...state.isChecked,
              lungs: newChecked,
              abdomen: newChecked,
              ribcage: newChecked,
              thoracicSpine: newChecked,
              diaphragm: newChecked,
              softTissues: newChecked,
              pleura: newChecked,
              selectAll: newChecked,
            },
          };
        } else {
          const { selectAll, ...otherCheckboxes } = state.isChecked;
          const allChecked = Object.values(otherCheckboxes).every(Boolean);
          return {
            ...state,
            isChecked: {
              ...state.isChecked,
              ...action.payload,
              selectAll: allChecked,
            },
          };
        }
    case UPDATE_SLIDER_VALUE:
      return {
        ...state,
        sliderValue: action.payload,
      };
    case UPDATE_AI_TEXT:
      return {
        ...state,
        aiText: action.payload,
      };
    default:
      return state;
  }
};

export default leftSidebarReducer;