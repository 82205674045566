import React, { useRef, useEffect, useImperativeHandle, forwardRef, useCallback  } from 'react';
import { RenderingEngine, Enums, init as csCoreInit } from '@cornerstonejs/core';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import * as cornerstoneTools from '@cornerstonejs/tools';
import DicomDropzone from '../../General-components/DicomDropzone';
import './ImageViewer.css';
import initCornerstoneDICOMImageLoader from 'utils/initCornerstoneDICOMImageLoader';
import { convertMultiframeImageIds } from 'utils/convertMultiframeImageIds';
import disableAllTools from 'utils/disableAllTools';
// cornerstonejs tools needed 
import {
  PanTool,
  ZoomTool,
  LengthTool,
  EllipticalROITool,
  RectangleROITool,
  AngleTool,
  ProbeTool,
  MagnifyTool,
  ToolGroupManager,
  Enums as csToolsEnums,
  init as csToolsInit,
} from '@cornerstonejs/tools';
import Toolbar from '../../General-components/Toolbar';
import { useSelector, useDispatch } from 'react-redux';
import { setImageLoaded, setImageStack, setToolsAdded  } from '../../../redux/ImageViewer/actions';
import { useImageContext } from '../../../pages/ImageContext';
import AppURLconfig from 'AppURLconfig';

const { MouseBindings } = csToolsEnums;
const { ViewportType } = Enums;

const ImageViewer = forwardRef(({ isLeftSidebarOpen, isRightSidebarOpen }, ref) => {
  const imageViewerRef = useRef(null);
  const viewportRef = useRef(null);
  const {setUploadedImage } = useImageContext();
  
  const dispatch = useDispatch();
  const { isImageLoaded, imageStack, toolsAdded } = useSelector((state) => state.imageViewer);
  const { selectedAnatomyURL, boundingBoxData } = useSelector((state) => state.PDLeftSidebar);
  const { selectedCase } = useSelector((state) => state.PatientDatabase);
  const prevClientWidthRef = useRef(0);

  // Function to handle image upload
  const handleImageUpload = (image) => {
    setUploadedImage(image);
  };


  useImperativeHandle(ref, () => {
    let originalWindowWidth = null;
    let originalUpper = null;
    let originalLower = null;
    let currentContrast = 50; // Init
    let currentBrightness = 50; // Init
  
    return {

      updateWindowLevel: (contrast, brightness, threshold) => {
        if (viewportRef.current) {
          const viewportProperties = viewportRef.current.getProperties();
          const currentVOI = viewportProperties.voiRange;
  
          if (currentVOI) {
            const currentWindowWidth = currentVOI.upper - currentVOI.lower;
  
            if (originalWindowWidth === null) {
              originalUpper = currentVOI.upper;
              originalLower = currentVOI.lower;
              originalWindowWidth = currentWindowWidth;
            }

            // Update current contrast and brightness values
            if (contrast !== undefined) {
              currentContrast = contrast;
            }
            if (brightness !== undefined) {
              currentBrightness = brightness;
            }
            // console.log(contrast, brightness, threshold)
            // Contrast
            const contrastFactor = currentContrast / 50;
            const newWindowWidth = originalWindowWidth * contrastFactor;
            // Brightness
            const brightnessFactor = currentBrightness / 100;
            const newWindowCenter = originalWindowWidth * brightnessFactor;

            // Lower and Upper
            let newLower = newWindowCenter - newWindowWidth / 2;
            let newUpper = newWindowCenter + newWindowWidth / 2;

            // thresholding of image
            let thresholdLower, thresholdUpper;
            if (threshold !== undefined) {
              const thresholdValue = originalLower + (originalUpper - originalLower) * (threshold / 100);
              thresholdLower = Math.max(originalLower, Math.floor(thresholdValue));
              thresholdUpper = Math.min(originalUpper, thresholdLower + 1);
            }

            // If contrast and brightness are undefined, set lower to thresholdLower and upper to thresholdUpper
            if (contrast === undefined && brightness === undefined && threshold !== undefined) {
              newLower = thresholdLower;
              newUpper = thresholdUpper;
            }


            viewportRef.current.setProperties({
              voiRange: {
                lower: newLower,
                upper: newUpper,
              },
            });
  
            viewportRef.current.render();
          }
        }
      },

    };
  });

  useEffect(() => {
    const initializeCs = async () => {
      await csCoreInit();
      await csToolsInit();
      await initCornerstoneDICOMImageLoader();
    };

    initializeCs();


  }, []);

  const loadAndViewImage = useCallback(async (stack) => {
    if (viewportRef.current) {
      viewportRef.current.setStack(stack).then(() => {
        viewportRef.current.render();
      });
    } else {
      dispatch(setImageStack(stack));
    }
  }, [dispatch]);

  useEffect(() => {
    const loadSegImage = async () => {
      if (selectedAnatomyURL) {
        try {
          console.log('selectedAnatomyURL imageviewer comp', selectedAnatomyURL);
          const response = await fetch(`${AppURLconfig.backendUrl}/api/segmentation-image?url=${encodeURIComponent(selectedAnatomyURL)}`);
          if (!response.ok) {
            throw new Error('Error fetching DICOM file');
          }
  
          const blob = await response.blob();
          const file = new File([blob], 'segmentation_output.dcm', {
            type: 'application/dicom',
          });
          console.log('segmentation file this part is done', file);
          const imageId = cornerstoneDICOMImageLoader.wadouri.fileManager.add(file);
          const stack = convertMultiframeImageIds([imageId]);
          dispatch(setImageLoaded(true));
          loadAndViewImage(stack);
        } catch (error) {
          console.error('Error loading segmentation image:', error);
        }
      }
    };
  
    loadSegImage();
  }, [selectedAnatomyURL]);

  useEffect(() => {
    const loadBBoxImage = async () => {
      if (boundingBoxData.URL) {
        try {
          console.log('boundingBoxData.URL imageviewer comp', boundingBoxData.URL);
          const boundingBoxURL = boundingBoxData.URL;
          console.log(boundingBoxURL, 'boundingBoxURL');
          const response = await fetch(`${AppURLconfig.backendUrl}/api/bounding-box-image?url=${encodeURIComponent(boundingBoxURL)}`);
          if (!response.ok) {
            throw new Error('Error fetching DICOM file');
          }
  
          const blob = await response.blob();
          const file = new File([blob], 'bounding_box_output.dcm', {
            type: 'application/dicom',
          });
          console.log('bounding box file this part is done', file);
          const imageId = cornerstoneDICOMImageLoader.wadouri.fileManager.add(file);
          const stack = convertMultiframeImageIds([imageId]);
          dispatch(setImageLoaded(true));
          loadAndViewImage(stack);
        } catch (error) {
          console.error('Error loading bounding box image:', error);
        }
      }
    };
  
    loadBBoxImage();
  }, [boundingBoxData]);


  useEffect(() => {
    const initializeRenderingEngine = () => {
      const renderingEngineId = 'myRenderingEngine';
      const renderingEngine = new RenderingEngine(renderingEngineId);

      const viewportId = 'CT_STACK';
      const viewportInput = {
        viewportId,
        type: ViewportType.STACK,
        element: imageViewerRef.current,
        defaultOptions: {
          background: [0.0, 0.0, 0.0],
        },
      };

      renderingEngine.enableElement(viewportInput);

      viewportRef.current = renderingEngine.getViewport(viewportId);

      if (!toolsAdded) {
        // Add tools to Cornerstone Tools
        cornerstoneTools.addTool(PanTool);
        cornerstoneTools.addTool(ZoomTool);
        cornerstoneTools.addTool(LengthTool);
        cornerstoneTools.addTool(EllipticalROITool);
        cornerstoneTools.addTool(RectangleROITool);
        cornerstoneTools.addTool(AngleTool);
        cornerstoneTools.addTool(ProbeTool);
        cornerstoneTools.addTool(MagnifyTool);
  
        const toolGroupId = 'MY_TOOL_GROUP_ID';
        const toolGroup = ToolGroupManager.createToolGroup(toolGroupId);
  
        // Add tools to the tool group
        toolGroup.addTool(PanTool.toolName);
        toolGroup.addTool(ZoomTool.toolName);
        toolGroup.addTool(LengthTool.toolName);
        toolGroup.addTool(EllipticalROITool.toolName);
        toolGroup.addTool(RectangleROITool.toolName);
        toolGroup.addTool(AngleTool.toolName);
        toolGroup.addTool(ProbeTool.toolName);
        toolGroup.addTool(MagnifyTool.toolName);
  
        // Set the tools as disabled
        toolGroup.setToolDisabled(PanTool.toolName);
        toolGroup.setToolDisabled(ZoomTool.toolName);
        toolGroup.setToolDisabled(LengthTool.toolName);
        toolGroup.setToolDisabled(EllipticalROITool.toolName);
        toolGroup.setToolDisabled(RectangleROITool.toolName);
        toolGroup.setToolDisabled(AngleTool.toolName);
        toolGroup.setToolDisabled(ProbeTool.toolName);
        toolGroup.setToolDisabled(MagnifyTool.toolName);
  
        // Add the viewport to the tool group
        toolGroup.addViewport(viewportId, renderingEngineId);
  
        dispatch(setToolsAdded(true));
      }
  

      if (imageStack) {
        loadAndViewImage(imageStack);
      }
    };
  
    if (isImageLoaded) {
      initializeRenderingEngine();
    }
  }, [isImageLoaded]);
  
  useEffect(() => {
    if (imageStack) {
      loadAndViewImage(imageStack);
    }
  }, [imageStack, loadAndViewImage]);


  useEffect(() => {
    const loadSelectedCase = async () => {
      if (selectedCase) {
        try {
          const dicomFname = selectedCase;
          const response = await fetch(`${AppURLconfig.backendUrl}/api/patient-database?fname=${encodeURIComponent(dicomFname)}`);
          if (!response.ok) {
            throw new Error('Error fetching DICOM file');
          }
  
          const blob = await response.blob();
          const file = new File([blob], dicomFname, { type: 'application/dicom' });
          handleImageUpload(file);
          const imageId = cornerstoneDICOMImageLoader.wadouri.fileManager.add(file);
          const stack = convertMultiframeImageIds([imageId]);
          dispatch(setImageLoaded(true));
          loadAndViewImage(stack);
        } catch (error) {
          console.error('Error loading selected case:', error);
        }
      }
    };
  
    loadSelectedCase();
  }, [selectedCase]);


  const handleFileDrop = async (acceptedFiles) => {
    console.log(acceptedFiles, 'acceptedFiles')
    const file = acceptedFiles[0];
    handleImageUpload(file);
    const imageId = cornerstoneDICOMImageLoader.wadouri.fileManager.add(file);
    const stack = convertMultiframeImageIds([imageId]);
    dispatch(setImageLoaded(true));
    loadAndViewImage(stack);
  };

  useEffect(() => {
    if (isImageLoaded && viewportRef.current) {
      const { element, canvas } = viewportRef.current;
      // console.log(canvas.width, canvas.height, '-------------------------------canvas width and height');
      // console.log(element.clientWidth, element.clientHeight, 'element width and height');
  
      const updateCanvasDimensions = () => {
        const imageWidth = canvas.width;
        const imageHeight = canvas.height;
        // console.log(imageWidth, imageHeight, 'image width and height');
  
        const currentClientWidth = element.clientWidth;
        const widthIncrease = currentClientWidth - prevClientWidthRef.current;
  
        // Adjust width based on sidebar states
        let newWidth = imageWidth;
        let newHeight = imageHeight;
  
        if (!isLeftSidebarOpen && !isRightSidebarOpen) {
          newWidth += 2 * widthIncrease; // Increase width by the sum of both sidebar widths
        } else if (!isLeftSidebarOpen) {
          newWidth += widthIncrease; // Increase width by the left sidebar width
        } else if (!isRightSidebarOpen) {
          newWidth += widthIncrease; // Increase width by the right sidebar width
        }
  
        if (!isLeftSidebarOpen || !isRightSidebarOpen) {
          // console.log('is left sidebar open', !isLeftSidebarOpen);
          // console.log('is right sidebar open', !isRightSidebarOpen);
          // console.log(newWidth, 'new client width');
  
          const aspectRatio = imageWidth / imageHeight;
          newHeight = newWidth / aspectRatio;
  
          // console.log(newHeight, 'new client height');
          // console.log('---------------\n');
  
          //element.style.width = `${newWidth}px`;
          //element.style.height = `${newHeight}px`;
          canvas.width = newWidth;
          canvas.height = newHeight;
  
          viewportRef.current.render(); // Render the image with updated canvas size
        }
  
        prevClientWidthRef.current = currentClientWidth;
      };
  
      // Initial update based on loaded image dimensions
      updateCanvasDimensions();
  
      return () => {}; // No cleanup needed
    }
  }, [isImageLoaded, isLeftSidebarOpen, isRightSidebarOpen]);

  const handleRightClick = (event) => {
    event.preventDefault();
  };

  const activateTool = (toolName) => {
    const toolGroupId = 'MY_TOOL_GROUP_ID';
    const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);


    // Disable all tools
    disableAllTools();

    // Enable the selected tool
    if (toolName === 'Zoom') {
      toolGroup.setToolActive(ZoomTool.toolName, {
        bindings: [
          { mouseButton: MouseBindings.Primary },
          { mouseButton: MouseBindings.Secondary },
        ],
      });
    } else if (toolName === 'Pan') {
      toolGroup.setToolActive(PanTool.toolName, {
        bindings: [
          { mouseButton: MouseBindings.Primary },
          { mouseButton: MouseBindings.Auxiliary },
        ],
      });
    } else if (toolName === 'Length') {
      toolGroup.setToolActive(LengthTool.toolName, {
        bindings: [
          { mouseButton: MouseBindings.Primary },
        ],
      });
    } else if (toolName === 'EllipticalROI') {
      toolGroup.setToolActive(EllipticalROITool.toolName, {
        bindings: [
          { mouseButton: MouseBindings.Primary },
        ],
      });
    } else if (toolName === 'RectangleROI') {
      toolGroup.setToolActive(RectangleROITool.toolName, {
        bindings: [
          { mouseButton: MouseBindings.Primary },
        ],
      });
    } else if (toolName === 'Angle') {
      toolGroup.setToolActive(AngleTool.toolName, {
        bindings: [
          { mouseButton: MouseBindings.Primary },
        ],
      });
    } else if (toolName === 'Probe') {
      toolGroup.setToolActive(ProbeTool.toolName, {
        bindings: [
          { mouseButton: MouseBindings.Primary },
        ],
      });
    } else if (toolName === 'Magnify') {
      toolGroup.setToolActive(MagnifyTool.toolName, {
        bindings: [
          { mouseButton: MouseBindings.Primary },
        ],
      });
    } 
    // else if (toolName === 'Eraser') {
    //   toolGroup.setToolActive(EraserTool.toolName, {
    //     bindings: [
    //       { mouseButton: MouseBindings.Primary },
    //     ],
    //   });
    // }
  };

  return (
    <div className="image-viewer">
      {isImageLoaded && <Toolbar activateTool={activateTool} />}
      <div className="image-viewer-container">
        {!isImageLoaded ? (
          <div className="dicomDropzone">
            <DicomDropzone onFileDrop={handleFileDrop} />
          </div>
        ) : (
          <div
            ref={imageViewerRef}
            className="viewport-container"
            onContextMenu={handleRightClick}
          ></div>
        )}
      </div>
    </div>
  );
});

export default ImageViewer;