import React, { useState } from 'react';
import Typography from '@mui/joy/Typography';
import InfoTooltip from '../../General-components/InfoTooltip';
import { useSelector, useDispatch } from 'react-redux';
import { updateDetectedPathologies, updatePathologyPredictionClicked } from '../../../redux/PDRightSidebar/actions';
import CustomBar from '../../General-components/CustomBar';
import axios from 'axios';
import { useImageContext } from '../../../pages/ImageContext';
import { Button, CircularProgress } from '@mui/material';
import ArrowIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import AppURLconfig from 'AppURLconfig';

const PDRightSidebar = ({ className, onToggle }) => {
  const dispatch = useDispatch();
  const { detectedPathologies, pathologyPredictionClicked } = useSelector((state) => state.PDRightSidebar);
  const { uploadedImage } = useImageContext();
  const [isLoading, setIsLoading] = useState(false);

  /* Detected Pathologies state setup ---------------*/
  const handleDetectedPathologiesUpdate = (newPathologies) => {
    dispatch(updateDetectedPathologies(newPathologies));
  };

  /* Pathology Detection Switch state setup ---------------*/
  const handlePathologyPredictionClick = async () => {
    dispatch(updatePathologyPredictionClicked());

    if (!pathologyPredictionClicked) {
      try {
        setIsLoading(true);
        console.log('Uploaded image pdfrightsidebar:', uploadedImage); // Debug

        // Create a new FormData object
        const formData = new FormData();
        formData.append('file', uploadedImage);

        console.log('FormData:', formData); // Debug

        const response = await axios.post(`${AppURLconfig.backendUrl}/api/predict`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const predict = response.data;
        handleDetectedPathologiesUpdate(predict);
      } catch (error) {
        console.error('Error generating report:', error);
        // Handle error state or display an error message
      } finally {
        setIsLoading(false);
      }
    }
  };


  return (
    <div className={className}>
      <div className='pathology-scores-group'>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <Typography
          variant="h3"
          sx={{
            fontSize: '1.1rem',
            fontWeight: 400,
            color: 'var(--text-primary)',
            paddingRight: '1px',
            paddingBottom: '10px',
            paddingLeft: '10px',
            paddingTop: '10px',
          }}
        >
          Detected Pathologies
        </Typography>
        <InfoTooltip message="List of detected pathologies and their confidence levels" />
        
      </div>
      {detectedPathologies.length > 0 && (<div className="anatomy-selector-divider"></div> )}
      {Object.entries(detectedPathologies).map(([pathology, value]) => (
        <div key={pathology}>
          <Typography
          sx = {{
            fontSize: '0.9rem',
            fontWeight: 400,
            color: 'var(--text-primary)',
            padding: '10px 10px 10px 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >{pathology}</Typography>
          <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <CustomBar value={value} />
          </div>
        </div>
      ))}
      </div>
      <div className='pathology-prediction-btn-div'>
        <Button
          variant='soft'
          onClick={handlePathologyPredictionClick}
          disabled={isLoading}    // TODO add this when we have a loading state
          sx={{
            backgroundImage: 'var(--button-primary-background)',
            color: 'var(--text-dark)',
            fontSize: '0.7rem',
            fontWeight: 500,
            padding: '0.4rem',
            width: '12rem',
            borderRadius: '8px',
            margin: 'auto',
            '&:hover': {
              backgroundColor: '#3B4B4DAB',
            },
            '&:active': {
              backgroundColor: '#3B4B4DAB',
              color: 'var(--text-dark)',
            },
            pointerEvents: isLoading ? 'none' : 'auto',
            opacity: isLoading ? 0.7 : 1,
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <>
            Pathology Prediction
            <ArrowIcon /> 
            </>
          )}
          
        </Button>
</div>
    </div>
  );
};

export default PDRightSidebar;