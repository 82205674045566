import React from 'react';
import { Box, Modal, Button, Typography } from '@mui/material';

const Disclaimer = ({ open, onAccept, onReject }) => {
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: '#E1ECDEEE',
          color: 'var(--text-dark)',
          boxShadow: 24,
          p: 4,
          borderRadius: '8px',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Disclaimer
        </Typography>
        <Typography>
        <b>This demo application is for informational purposes only.</b> The AI-generated reports, anatomy segmentation, and pathology detection features are under development and not intended for clinical use. The results should not be used to make diagnoses or treatment decisions. Those relying on professional medical expertise should continue to do so. For developers, this application showcases functionalities under development, and the performance may not reflect the final product. Anyone with medical concerns should consult a qualified healthcare professional.
        </Typography>
        <Box mt={4} display="flex" justifyContent="flex-end">
          <Button onClick={onReject} color="primary" variant="outlined" 
          sx={{ 
            mr: 2,
            border : '1px solid var(--text-dark)',
            color: 'var(--text-dark)',
            }}>
            Reject
          </Button>
          <Button onClick={onAccept} color="primary" variant="contained" 
          sx={{
            backgroundImage: 'var(--button-primary-background)',
            color: 'var(--text-dark)',
            fontSize: '0.7rem',
            fontWeight: 500,
            padding: '0.4rem',
            width: '7rem',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: '#3B4B4DAB',
            },
            '&:active': {
              backgroundColor: '#3B4B4DAB',
              color: 'var(--text-dark)',
            },
          }}
            >
            Accept
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default Disclaimer;