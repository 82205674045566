import jsPDF from 'jspdf';

export const generatePDF = (patientDetails, findings, impression) => {
  const doc = new jsPDF();

  // Add letterhead
  doc.setFontSize(20);
  doc.text('YC-Hospital', 10, 20);
  doc.setFontSize(12);
  doc.text('123 Main Street, XYZ City, India', 10, 30);
  doc.text('Phone: +91 1234567890', 10, 35);
  doc.text('Email: info@ychospital.com', 10, 40);

  // Add patient details
  doc.setFontSize(16);
  doc.text('Patient Details', 10, 60);
  doc.setFontSize(12);
  doc.text(`Name: ${patientDetails.name}`, 10, 70);
  doc.text(`ID: ${patientDetails.id}`, 10, 80);
  doc.text(`Age: ${patientDetails.age}`, 10, 90);
  doc.text(`Gender: ${patientDetails.sex}`, 10, 100);

  // Add AI-generated report
  doc.setFontSize(16);
  doc.text('AI-Generated Radiology Report', 10, 120);
  doc.setFontSize(12);
  doc.text('X-Ray Investigation', 10, 130);

  // Wrap findings and impression text
  const findingsText = doc.splitTextToSize(`Findings: ${findings}`, 180);
  doc.text(findingsText, 10, 140);

  const impressionText = doc.splitTextToSize(`Impression: ${impression}`, 180);
  doc.text(impressionText, 10, 140 + (findingsText.length * 10));

  // Add disclaimer
  doc.setFontSize(10);
  doc.text('This report is for investigation purposes only and should not be considered a final diagnosis.', 10, 280);

  // Save the PDF
  doc.save(`report_${patientDetails.id}.pdf`);
};