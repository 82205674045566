// src/components/Header.js
import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import './Header.css';
import logo from '../../assets/logo_white.png';

const Header = () => (
  <header>
    <div className="header-container">
      <div className="logo-container">
        <Link className='logo--link' to="/">
          <img className="logo--img" src={logo} alt="X-Ray Vision Logo" />
        </Link>
        <Link className='logo--link' to='/'>
          <h1 className='logo--name'>RadVision</h1>
        </Link>
      </div>
      <nav>
        <ul className="nav-links">
          <li>
            <NavLink to="/patient-database" className={({ isActive }) => isActive ? 'active' : ''}>Patient Database</NavLink>
          </li>
          <li>
            <NavLink to="/advanced-pre-screening" className={({ isActive }) => isActive ? 'active' : ''}>Advanced Pre-Screening</NavLink>
          </li>
          <li>
            <NavLink to="/pathology-detection" className={({ isActive }) => isActive ? 'active' : ''}>Pathology Detection</NavLink>
          </li>
          <li>
            <NavLink to="/reports-gen" className={({ isActive }) => isActive ? 'active' : ''}>Reports Generation</NavLink>
          </li>
          <li>
            <NavLink to="/immersive-viewer" className={({ isActive }) => isActive ? 'active' : ''}>Immersive Viewer</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  </header>
);

export default Header;