// Toolbar.js
import React, { useState } from 'react';
import './Toolbar.css';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PanIcon from '@mui/icons-material/OpenWith';
import ZoomIcon from '@mui/icons-material/Search';
import LengthIcon from '@mui/icons-material/Straighten';
import EllipticalROIIcon from '@mui/icons-material/RadioButtonUnchecked';
import RectangleROIIcon from '@mui/icons-material/Crop54';
import AngleIcon from '@mui/icons-material/Architecture';
import ProbeIcon from '@mui/icons-material/TouchApp';
import MagnifyIcon from '@mui/icons-material/ZoomIn';
// import EraserIcon from '@mui/icons-material/DeleteForeverRounded';


const Toolbar = ({ activateTool }) => {
  const [activeButton, setActiveButton] = useState(null);

  const tools = [
    { CSName: 'Zoom', name: 'Zoom In/Out Tool', icon: <ZoomIcon /> },
    { CSName: 'Pan', name: 'Pan Tool', icon: <PanIcon /> },
    { CSName: 'Length', name: 'Length Tool', icon: <LengthIcon /> },
    { CSName: 'EllipticalROI', name: 'Elliptical ROI Tool', icon: <EllipticalROIIcon /> },
    { CSName: 'RectangleROI', name: 'Rectangle ROI Tool', icon: <RectangleROIIcon /> },
    { CSName: 'Angle', name: 'Angle measurement Tool', icon: <AngleIcon /> },
    { CSName: 'Probe', name: 'Probe Tool', icon: <ProbeIcon /> },
    { CSName: 'Magnify', name: 'Magnify Tool', icon: <MagnifyIcon /> },
    // { CSName: 'Eraser', name: 'Eraser Tool', icon: <EraserIcon /> },
  ];

  const handleButtonClick = (toolName) => {
    activateTool(toolName);
    setActiveButton(toolName);
  };

  return (
    <div className="toolbar">
      <div className="toolbar-container">
        {tools.map((tool) => (
          <Tooltip key={tool.name} title={tool.name}>
            <IconButton
              className={`toolbar-button ${activeButton === tool.CSName ? 'active' : ''}`}
              onClick={() => handleButtonClick(tool.CSName)}
            >
              {tool.icon}
            </IconButton>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default Toolbar;