import {
  UPDATE_PATIENT_DETAILS,
  UPDATE_FINDINGS,
  UPDATE_IMPRESSION,
  SUBMIT_REPORT,
} from './actions';

const initialState = {
  patientDetails: {
    id: '',
    name: '',
    age: '',
    sex: '',
  },
  findings: '',
  impression: '',
};

const reportGenAreaReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PATIENT_DETAILS:
      return {
        ...state,
        patientDetails: {
          ...state.patientDetails,
          ...action.payload,
        },
      };
    case UPDATE_FINDINGS:
      return {
        ...state,
        findings: action.payload,
      };
    case UPDATE_IMPRESSION:
      return {
        ...state,
        impression: action.payload,
      };
    case SUBMIT_REPORT:
      // Implement submit report functionality
      return state;
    default:
      return state;
  }
};

export default reportGenAreaReducer;