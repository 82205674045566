import {
    UPDATE_DETECTED_PATHOLOGIES,
    UPDATE_PATHOLOGY_PREDICTION_CLICKED,
  } from './actions';
  
  const initialState = {
    detectedPathologies: [],
    pathologyPredictionClicked: false,
  };
  
  const PDRightSidebarReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_DETECTED_PATHOLOGIES:
        return { ...state, detectedPathologies: action.payload };
      case UPDATE_PATHOLOGY_PREDICTION_CLICKED:
        return { ...state, pathologyPredictionClicked: !state.pathologyPredictionClicked };
      default:
        return state;
    }
  };
  
  export default PDRightSidebarReducer;