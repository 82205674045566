export const SET_IMAGE_LOADED = 'SET_IMAGE_LOADED';
export const SET_IMAGE_STACK = 'SET_IMAGE_STACK';
export const SET_DICOM_IMAGE = 'SET_DICOM_IMAGE';
export const SET_TOOLS_ADDED = 'SET_TOOLS_ADDED';

export const setImageLoaded = (isLoaded) => ({
  type: SET_IMAGE_LOADED,
  payload: isLoaded,
});

export const setImageStack = (stack) => ({
  type: SET_IMAGE_STACK,
  payload: stack,
});

export const setDicomImage = (image) => ({
  type: SET_DICOM_IMAGE,
  payload: image,
});

export const setToolsAdded = (value) => ({
  type: SET_TOOLS_ADDED,
  payload: value,
});