import VideoPlayer from '../components/Page-Components/ImmersiveViewer/VideoPlayer';
import DicomViewportGrid from '../components/Page-Components/ImmersiveViewer/DicomViewportGrid';
import './ImmersiveViewer.css';
import Typography from '@mui/material/Typography';

const ImmersiveViewer = () => {
  return (
    <div className='immersive-viewer-page'>
      <DicomViewportGrid />
      <div className="ct-image-container">
        <Typography variant="h6" component="div" align="center" style={{ color: 'white' }}>
          CT Generated from X-ray
        </Typography>
        <VideoPlayer />
      </div>
    </div>
  );
};

export default ImmersiveViewer;