import React, { useState } from 'react';
import Typography from '@mui/joy/Typography'; 
import CustomSwitch from '../../General-components/CustomSwitch';
import CustomCheckbox from '../../General-components/CustomCheckbox';
import InfoTooltip  from '../../General-components/InfoTooltip';
import CustomSlider from '../../General-components/CustomSlider';
import Textarea from '@mui/joy/Textarea';
import { Button, CircularProgress } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateChecked,
  updateIsChecked,
  updateSliderValue,
  updateAiText,
} from '../../../redux/LeftSidebar/actions';
import { useImageContext } from '../../../pages/ImageContext';
import AppURLconfig from 'AppURLconfig';


const LeftSidebar = ({ className }) => {
  const dispatch = useDispatch();
  const { checked, isChecked, sliderValue, aiText } = useSelector((state) => state.LeftSidebar);
  const { uploadedImage } = useImageContext();
  const [isLoading, setIsLoading] = useState(false);


    /* Prioritize critical finding switch state setup ---------------*/
    const handleChange = (event) => {
      dispatch(updateChecked(event.target.checked));
    };
    /* Anatomy selector state setup ---------------------------------*/
    // Initial checkbox states (assuming all unchecked)
    const handleCheckboxChange = (event, value) => {
      if (value === 'selectAll') {
        dispatch(
          updateIsChecked({
            selectAll: event.target.checked,
          })
        );
      } else {
        dispatch(
          updateIsChecked({
            [value]: event.target.checked,
          })
        );
      }
    };
    /* Classification confidence slider state setup --------------*/
    // ranges go from 0 to 100 in steps of 1
    const handleSliderChange = (newValue) => {
      dispatch(updateSliderValue(newValue));
    };

    /* Preliminary report text area state setup -----------------*/
    const handleAIModelUpdate = (newText) => {
      dispatch(updateAiText(newText));
    };

    /* Button onClick function --------------------------*/
    const handleGenerateReport = async () => {
      try {
        setIsLoading(true);
    
        const formData = new FormData();
        formData.append('file', uploadedImage);
    
        const response = await axios.post(`${AppURLconfig.backendUrl}/api/generate-report`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    
        const reports = response.data;    
        const reportText = `Findings: ${reports.findings}\nImpression: ${reports.impression}`
        handleAIModelUpdate(reportText);
        
      } catch (error) {
        console.error('Error generating report:', error);
        // Handle error state or display an error message
      } finally {
        setIsLoading(false);
      }
    };

    /* Start preScreening button onClick function --------------------------*/
    // const handleStartPreScreening = () => {
    //     // Your logic here
    // };


    return (
      <div className={className}>
              <div className='anatomy-selector-container'>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <Typography
                    variant="h3"
                    sx={{ 
                      fontSize: '1.1rem', 
                      fontWeight: 400, 
                      color: 'var(--text-primary)', 
                      paddingRight: '1px',
                      paddingBottom: '10px', 
                      paddingLeft: '10px',
                      paddingTop: '10px',
                    }}
                > Anatomy Selector
                </Typography>
                <InfoTooltip message="Select anatomical regions of interest for analysis" />
              </div>
                <div className='anatomy-selector-divider'></div>
                <div className='anatomy-selector--col'>
                <div className='anatomy-selector--col1'>
                <CustomCheckbox
                    label="Lungs"
                    value="lungs"
                    checked={isChecked.lungs}
                    onChange={handleCheckboxChange}
                />
                <CustomCheckbox
                    label="Abdomen"
                    value="abdomen"
                    checked={isChecked.abdomen}
                    onChange={handleCheckboxChange}
                />
                <CustomCheckbox
                    label="Ribcage"
                    value="ribcage"
                    checked={isChecked.ribcage}
                    onChange={handleCheckboxChange}
                />
                <CustomCheckbox
                    label="Thoracic Spine"
                    value="thoracicSpine"
                    checked={isChecked.thoracicSpine}
                    onChange={handleCheckboxChange}
                />
                </div>
                <div className='anatomy-selector--col2'>
                <CustomCheckbox
                    label="Diaphragm"
                    value="diaphragm"
                    checked={isChecked.diaphragm}
                    onChange={handleCheckboxChange}
                />
                <CustomCheckbox
                    label="Soft Tissues"
                    value="softTissues"
                    checked={isChecked.softTissues}
                    onChange={handleCheckboxChange}
                />
                <CustomCheckbox
                    label="Pleura"
                    value="pleura"
                    checked={isChecked.pleura}
                    onChange={handleCheckboxChange}
                />
                <CustomCheckbox
                    label="Select all"
                    value="selectAll"
                    checked={isChecked.selectAll}
                    onChange={handleCheckboxChange}
                />
                </div>
                </div>
              </div>
              <Typography 
                    component="label" 
                    endDecorator={
                        <CustomSwitch 
                            initialState={checked}
                            onSwitchChange={handleChange} 
                        />} 
                    sx={{ 
                      fontSize: '0.9rem', 
                      fontWeight: 400, 
                      color: 'var(--text-primary)', 
                      paddingLeft: '20px',
                      paddingRight: '20px', 
                      display: 'flex',  
                      alignItems: 'center', 
                      justifyContent: 'space-between',
                      marginTop: '20px',
                  }}
                    >
                Prioritize Critical Findings
              </Typography>
              <div className='classification-confidence-container'>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <Typography
                    variant="h3"
                    sx={{ 
                      fontSize: '1.1rem', 
                      fontWeight: 400, 
                      color: 'var(--text-primary)', 
                      paddingRight: '1px',
                      paddingBottom: '10px', 
                      paddingLeft: '10px',
                      paddingTop: '10px',
                    }}
                > Classification Score
                </Typography>
                <InfoTooltip message="Set the minimum confidence level for classification" />
              </div>
              <div className='slider'>
                <CustomSlider 
                    ranges={[0, 100]}
                    initialValue={sliderValue} 
                    marks={[{ value: 0, label: '0' }, { value: 100, label: '100' }]} 
                    onChange={handleSliderChange}
                />
              </div>


            </div>

            <div className='prelim-report-container'>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <Typography
                    variant="h3"
                    sx={{ 
                      fontSize: '1.1rem', 
                      fontWeight: 400, 
                      color: 'var(--text-primary)', 
                      paddingRight: '1px',
                      paddingBottom: '10px', 
                      paddingLeft: '10px',
                      paddingTop: '10px',
                    }}
                > Preliminary Reports
                </Typography>
                <InfoTooltip message="Generate preliminary reports for the selected anatomical regions" />
              </div>
              <Textarea 
                variant='outlined'
                color='neutral'
                className='prelim-report-textarea'
                placeholder={isLoading ? 'AI-generated report is being fetched' : 'AI-generated preliminary report'}
                value={isLoading ? '' : aiText}
                onChange={(e) => handleAIModelUpdate(e.target.value)}
                minRows={2}
                maxRows={4}
                sx={{
                  '&::before': {
                    display: 'none',
                    height: '10px',
                  },
                  '&:focus-within': {
                    outline: '1.5px solid var(--nav-hover)',
                    outlineOffset: '2px',
                  },
                  // change the background color of the textarea
                  width: '100%',
                  fontSize: '0.9em', 
                  backgroundColor: isLoading ? '#E0E0E0' : '#E4EEE3F9',
                  color: isLoading ? 'transparent' : '#201f1f',
                  animation: isLoading ? 'skeleton-loading 1.5s infinite' : 'none',
                }}
              />
              <Button
                // onClick={handleGenerateReport}    // TODO add this later on when we add the function
                variant="soft"
                size="sm"

                loading={false}    // TODO add this when we have a loading state
                sx={{
                  backgroundImage: 'linear-gradient(45deg, #CD7C6E, #E3A39D, #CD7C6E)',
                  color: 'var(--text-dark)',
                  fontSize: '0.7rem',
                  fontWeight: 400,
                  padding: '5px 10px',
                  margin: '0 auto',
                  marginTop: '15px',
                  marginBottom: '10px',
                  width: '13rem',
                  transition: 'background-image 0.3s',
                  borderRadius: '5px',
                  '&:hover': {
                    backgroundImage: 'linear-gradient(45deg, #C26060, #D88E8E, #C26060)',
                  },
                  '&:active': {
                    backgroundImage: 'linear-gradient(45deg, #B55555, #CD7C7C, #B55555)',
                    color: 'black',
                  },
                }}

              >Abnormality Detection
              <ArrowForwardIosIcon sx={{ fontSize: '1.1rem' }} />
              </Button>
              
              </div>
              <div className='start-prescreening-btn'>
              <Button
                onClick={handleGenerateReport}    // TODO add this later on when we add the function
                variant="soft"
                size="sm"
                disabled={isLoading}    // TODO add this when we have a loading state
                sx = {{
                  backgroundImage: 'var(--button-primary-background)',
                  color: 'var(--text-dark)',
                  fontSize: '0.9rem',
                  fontWeight: 500,
                  padding: '0.7rem',
                  width: '14rem',
                  borderRadius: '18px',
                  '&:hover': {
                    backgroundColor: '#3B4B4DAB',
                  },
                  '&:active': {
                    backgroundColor: '#3B4B4DAB',
                    color: 'var(--text-dark)',
                  },
                  pointerEvents: isLoading ? 'none' : 'auto',
                  opacity: isLoading ? 0.7 : 1,
                }}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Start Pre-Screening'
                )}
              </Button>
              </div>
              
        
        </div>
    );
};

export default LeftSidebar;
