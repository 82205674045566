import React, { useRef, useEffect, useState } from 'react';
import AppURLconfig from 'AppURLconfig';

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const sliderRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await fetch(`${AppURLconfig.backendUrl}/api/xray2ct`);
        const videoBlob = await response.blob();
        const url = URL.createObjectURL(videoBlob);
        setVideoUrl(url);
      } catch (error) {
        console.error('Error fetching video:', error);
      }
    };

    fetchVideo();
  }, []);

  const handleSliderChange = (event) => {
    const seekTime = parseFloat(event.target.value);
    if (videoRef.current) {
      videoRef.current.currentTime = seekTime;
    }
  };

  const handleVideoTimeUpdate = () => {
    if (sliderRef.current && videoRef.current) {
      sliderRef.current.value = videoRef.current.currentTime;
    }
  };

  return (
    <div>
      {videoUrl && (
        <>
          <video
            ref={videoRef}
            src={videoUrl}
            // style={{ width: '100%', height: 'auto' }}
            muted
            onTimeUpdate={handleVideoTimeUpdate}
          />
          <input
            ref={sliderRef}
            type="range"
            min="0"
            max="12"
            step="0.09"
            defaultValue="0"
            onChange={handleSliderChange}
            style={{ width: '100%' }}
          />
        </>
      )}
    </div>
  );
};

export default VideoPlayer;