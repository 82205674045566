// pages/ResetPasswordForm.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../redux/auth/authActions';
import { Button, TextField } from '@mui/material';

const ResetPasswordForm = () => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleResetPassword = (e) => {
    e.preventDefault();
    dispatch(resetPassword(email))
      .unwrap()
      .then(() => {
        alert('Password reset email sent');
        navigate('/auth');
      });
  };

  return (
    <form onSubmit={handleResetPassword}>
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        sx={{
            '& .MuiInputBase-input': {
              color: 'white',
            },
            '& .MuiInputLabel-root': {
              color: 'white',
              '&.Mui-focused': {
                color: 'white',
              },
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white',
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
            },
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          }}
      />
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
      <Button type="submit" variant="contained" color="primary" fullWidth
      sx={{
        backgroundImage: 'var(--button-primary-background)',
        color: 'var(--text-dark)',
        fontSize: '0.9rem',
        fontWeight: 500,
        padding: '0.7rem',
        width: '14rem',
        borderRadius: '18px',
        '&:hover': {
          backgroundColor: '#3B4B4DAB',
        },
        '&:active': {
          backgroundColor: '#3B4B4DAB',
          color: 'var(--text-dark)',
        },
      }}
      >
        Reset Password
      </Button>
      </div>
    </form>
  );
};

export default ResetPasswordForm;