import {
    UPDATE_ANATOMY_SELECTED,
    UPDATE_HIGHLIGHT_ANATOMY_CLICKED,
    UPDATE_HIGHLIGHT_PATHOLOGY_CLICKED,
    UPDATE_SEGMENTATION_DATA,
    UPDATE_BOUNDINGBOX_DATA,
    UPDATE_SELECTED_ANATOMY_URL,
  } from './actions';
  
  const initialState = {
    anatomySelected: '',
    pathologyIsChecked: {
      pneumonia: false,
      pneumothorax: false,
      atelectasis: false,
      cardiomegaly: false,
      pleuralEffusion: false,
      fractures: false,
    },
    highlightAnatomyClicked: false,
    highlightPathologyClicked: false,
    detectPathologiesChecked: false,
    segmentationData: null,
    boundingBoxData: {
      URL: null,
      diseaseList: [],
    },
    selectedAnatomyURL: null,
  };
  
  const PDLeftSidebarReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_ANATOMY_SELECTED:
      return { ...state, anatomySelected: action.payload };
      case UPDATE_HIGHLIGHT_ANATOMY_CLICKED:
        return { ...state, highlightAnatomyClicked: !state.highlightAnatomyClicked };
      case UPDATE_HIGHLIGHT_PATHOLOGY_CLICKED:
        return { ...state, highlightPathologyClicked: !state.highlightPathologyClicked };
      case UPDATE_SEGMENTATION_DATA:
        return { ...state, segmentationData: action.payload };
      case UPDATE_BOUNDINGBOX_DATA:
        return { ...state, boundingBoxData: action.payload };
      case UPDATE_SELECTED_ANATOMY_URL:
        return { ...state, selectedAnatomyURL: action.payload };
      default:
        return state;
    }
  };
  
  export default PDLeftSidebarReducer;