export const UPDATE_ANATOMY_SELECTED = 'UPDATE_ANATOMY_SELECTED';
export const UPDATE_HIGHLIGHT_ANATOMY_CLICKED = 'UPDATE_HIGHLIGHT_ANATOMY_CLICKED';
export const UPDATE_HIGHLIGHT_PATHOLOGY_CLICKED = 'UPDATE_HIGHLIGHT_PATHOLOGY_CLICKED';
export const UPDATE_SEGMENTATION_DATA = 'UPDATE_SEGMENTATION_DATA';
export const UPDATE_BOUNDINGBOX_DATA = 'UPDATE_BOUNDINGBOX_DATA';
export const UPDATE_SELECTED_ANATOMY_URL = 'UPDATE_SELECTED_ANATOMY_URL';

export const updateAnatomySelected = (selectedAnatomy) => ({
  type: UPDATE_ANATOMY_SELECTED,
  payload: selectedAnatomy,
});

export const updateHighlightAnatomyClicked = () => ({
  type: UPDATE_HIGHLIGHT_ANATOMY_CLICKED,
});

export const updateHighlightPathologyClicked = () => ({
  type: UPDATE_HIGHLIGHT_PATHOLOGY_CLICKED,
});

export const updateSegmentationData = (segmentationData) => ({
  type: UPDATE_SEGMENTATION_DATA,
  payload: segmentationData,
});

export const updateBoundingBoxData = (boundingBoxData) => ({
  type: UPDATE_BOUNDINGBOX_DATA,
  payload: boundingBoxData,
});

export const updateSelectedAnatomyURL = (selectedAnatomyURL) => ({
  type: UPDATE_SELECTED_ANATOMY_URL,
  payload: selectedAnatomyURL,
});