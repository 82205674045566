import React from 'react';
import Switch, { switchClasses } from '@mui/joy/Switch';


const CustomSwitch = ({ initialState, onSwitchChange }) => {
  
  return ( 
      <Switch
        checked={initialState}
        onChange={onSwitchChange}
        sx={{
          '--Switch-thumbShadow': '0 3px 7px 0 var(--switch-shadow)',
          '--Switch-thumbSize': '18px',
          '--Switch-trackWidth': '55px',
          '--Switch-trackHeight': '25px',
          '--Switch-trackBackground': 'var(--switch-off-background)',
          [`& .${switchClasses.thumb}`]: {
            transition: 'width 0.2s, left 0.2s',
            backgroundColor: 'var(--switch-thumb-off)',
          },
          '&:hover': {
            '--Switch-trackBackground': 'var(--border-primary)',
          },
          '&:active': {
            '--Switch-thumbWidth': '32px',
          },
          [`&.${switchClasses.checked}`]: {
            '--Switch-trackBackground': 'var(--switch-on-background)',
            [`& .${switchClasses.thumb}`]: {
              backgroundColor: 'var(--switch-thumb-on)',
            },
            '&:hover': {
              '--Switch-trackBackground': 'var(--primary-color)',
            },
          },
        }}
        />
  );
};

export default CustomSwitch; 
