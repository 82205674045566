import { TOGGLE_PD_LEFT_SIDEBAR, TOGGLE_PD_RIGHT_SIDEBAR } from './actions';

const initialState = {
  isPDLeftSidebarOpen: true,
  isPDRightSidebarOpen: true,
};

const PathologyDetectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_PD_LEFT_SIDEBAR:
      return { ...state, isPDLeftSidebarOpen: !state.isPDLeftSidebarOpen };
    case TOGGLE_PD_RIGHT_SIDEBAR:
      return { ...state, isPDRightSidebarOpen: !state.isPDRightSidebarOpen };
    default:
      return state;
  }
};

export default PathologyDetectionReducer;