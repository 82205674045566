import React from 'react';
import Tooltip from '@mui/joy/Tooltip';
import IconButton from '@mui/joy/IconButton'; 
import InfoIcon from '@mui/icons-material/InfoOutlined';

const InfoTooltip = ({ message }) => {
  return (
    <Tooltip 
        title={message} 
        arrow
        placement="right-start"
        sx={{
            bgcolor: 'rgba(0, 0, 0, 0.7)',  // Semi-transparent dark background 
            color: 'white',                 // High contrast text
            borderRadius: '8px',            // Soften the corners
            fontSize: '14px',               // Ensure readability 
            py: 0.5, px: 1,                // Add some padding  
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',  // Subtle shadow for depth
        }}
    >
      <IconButton
        sx={{ 
            size: 'small', // Set the size of the icon button
            bgcolor: 'transparent', // Makes the background transparent
            '&:hover': {
                bgcolor: 'transparent', // Keep background transparent on hover
            }
          }}
      >
        <InfoIcon 
        fontSize="4px"
        sx={{
            color: 'var(--secondary-white)', // Set the color of the icon
        
        }}
        />
      </IconButton>
    </Tooltip>
  );
};

export default InfoTooltip;
