import React, { useState } from 'react';
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Button,
  Chip,
  CircularProgress,
} from '@mui/material';
import { Search, FilterList, Add } from '@mui/icons-material';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedCase } from '../redux/PatientDatabase/actions';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingIcon from '@mui/icons-material/Pending';

const SearchBar = styled(TextField)({
  marginBottom: '16px',
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
    '&.Mui-focused': {
      color: 'white',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
    },
    '&:hover fieldset': {
      borderColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
    },
  },
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
});

const FilterButton = styled(Button)({
  backgroundImage: 'var(--button-primary-background)',
  color: 'var(--text-dark)',
  fontSize: '0.7rem',
  fontWeight: 500,
  padding: '0.7rem',
  width: '11rem',
  borderRadius: '18px',
  '&:hover': {
    backgroundColor: '#3B4B4DAB',
  },
  '&:active': {
    backgroundColor: '#3B4B4DAB',
    color: 'var(--text-dark)',
  },
});

const AddButton = styled(Button)({
  backgroundImage: 'var(--button-primary-background)',
  color: 'var(--text-dark)',
  fontSize: '0.7rem',
  fontWeight: 500,
  padding: '0.7rem',
  width: '7rem',
  borderRadius: '18px',
  '&:hover': {
    backgroundColor: '#3B4B4DAB',
  },
  '&:active': {
    backgroundColor: '#3B4B4DAB',
    color: 'var(--text-dark)',
  },
});

const getRowColor = (aiPriority) => {
  if (aiPriority >= 0 && aiPriority < 20) {
    return 'linear-gradient(to right, rgba(255, 255, 255, 0), #C8E6C97F)';
  } else if (aiPriority >= 20 && aiPriority < 40) {
    return 'linear-gradient(to right, rgba(255, 255, 255, 0), #FFF9C490)';
  } else if (aiPriority >= 40 && aiPriority < 60) {
    return 'linear-gradient(to right, rgba(255, 255, 255, 0), #FFCC808E)';
  } else if (aiPriority >= 60 && aiPriority < 80) {
    return 'linear-gradient(to right, rgba(255, 255, 255, 0), #EF9A9A8A)';
  } else if (aiPriority >= 80 && aiPriority <= 100) {
    return 'linear-gradient(to right, rgba(255, 255, 255, 0), #F4433681)';
  }
};

const PatientDatabase = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [patients, setPatients] = useState([
    {id: 1, name: 'John Doe', patientId: 'P001', age: 35, gender: 'Male', modality: 'X-ray', lastVisit: '2023-04-28', aiPriority: 85, reportStatus: 'Pending', expanded: true, dicomLink: 'p001.dcm'},
    {id: 2, name: 'Jane Smith', patientId: 'P002', age: 42, gender: 'Female', modality: 'CT', lastVisit: '2023-04-27', aiPriority: 70, reportStatus: 'Completed', expanded: false, dicomLink: 'p002.dcm'},
    {id: 3, name: 'Alice Johnson', patientId: 'P003', age: 28, gender: 'Female',  modality: 'X-ray',  lastVisit: '2023-04-26',  aiPriority: 20,  reportStatus: 'Pending',  expanded: false, dicomLink: 'p003.dcm'},
    // Add more patient data here
  ]);
 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isInvestigateCaseLoading, setIsInvestigateCaseLoading] = useState(false);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterClick = () => {
    const sortedPatients = [...patients].sort((a, b) => b.aiPriority - a.aiPriority);
    setPatients(sortedPatients);
  };

  const handleAddClick = () => {
    // Implement the logic to add a new patient scan
  };

  const handleRowClick = (patientId) => {
    const updatedPatients = patients.map((patient) => {
      if (patient.id === patientId) {
        return { ...patient, expanded: !patient.expanded };
      }
      return patient;
    });
    setPatients(updatedPatients);
  };

  const handleInvestigateClick = (dicomLink) => {
    setIsInvestigateCaseLoading(true);
  // Simulate a delay of 1.5 seconds
    setTimeout(() => {
      setIsInvestigateCaseLoading(false);
    dispatch(setSelectedCase(dicomLink));
    navigate('/advanced-pre-screening', { replace: true });
    }, 800);
  };

  const filteredPatients = patients.filter((patient) =>
    patient.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div style={{ background: '#7D9A7663', padding: '50px 15px 50px 15px', margin:'30px'}}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <SearchBar
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <Search />,
          }}
          sx={{
            '& .MuiInputBase-input': {
              color: 'white',
            },
            '& .MuiInputLabel-root': {
              color: 'white',
              '&.Mui-focused': {
                color: 'white',
              },
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'white',
              },
              '&:hover fieldset': {
                borderColor: 'white',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'white',
              },
            },
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
          }}
        />
        <FilterButton variant="contained" startIcon={<FilterList />} onClick={handleFilterClick}>
          Filter by Priority
        </FilterButton>
        <AddButton variant="contained" startIcon={<Add />} onClick={handleAddClick}>
          Add Scan
        </AddButton>
      </div>
      <TableContainer component={Paper} style={{ marginTop: '16px', backgroundColor: 'transparent' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{color:'white'}}>Patient Name</TableCell>
              <TableCell sx={{color:'white'}}>Patient ID</TableCell>
              <TableCell sx={{color:'white'}}>Age</TableCell>
              <TableCell sx={{color:'white'}}>Gender</TableCell>
              <TableCell sx={{color:'white'}}>Modality</TableCell>
              <TableCell sx={{color:'white'}}>Last Visit</TableCell>
              <TableCell sx={{color:'white'}}>AI Priority %</TableCell>
              <TableCell sx={{color:'white'}}>Report Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPatients.map((patient) => (
              <React.Fragment key={patient.id}>
                <TableRow onClick={() => handleRowClick(patient.id)} style={{ background: getRowColor(patient.aiPriority) }}>
                  <TableCell sx={{color:'white'}}>{patient.name}</TableCell>
                  <TableCell sx={{color:'white'}}>{patient.patientId}</TableCell>
                  <TableCell sx={{color:'white'}}>{patient.age}</TableCell>
                  <TableCell sx={{color:'white'}}>{patient.gender}</TableCell>
                  <TableCell sx={{color:'white'}}>{patient.modality}</TableCell>
                  <TableCell sx={{color:'white'}}>{patient.lastVisit}</TableCell>
                  <TableCell sx={{color:'white'}}>{patient.aiPriority}%</TableCell>
                  <TableCell>
                  <Chip
                    variant="outlined"
                    size="small"
                    icon={
                      patient.reportStatus === 'Completed' ? (
                        <CheckCircleOutlineIcon
                          fontSize="small"
                          style={{
                            color: patient.reportStatus === 'Completed' ? '#4caf50' : '#ff9800',
                          }}
                        />
                      ) : (
                        <PendingIcon
                          fontSize="small"
                          style={{
                            color: patient.reportStatus === 'Completed' ? '#4caf50' : '#ff9800',
                          }}
                        />
                      )
                    }
                    label={patient.reportStatus}
                    style={{
                      color: patient.reportStatus === 'Completed' ? '#6BD671' : '#FFFFFF',
                      borderColor: patient.reportStatus === 'Completed' ? '#6BD671' : '#FFFFFF',
               
                    }}
                  />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={8} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={patient.expanded} timeout="auto" unmountOnExit>
                      <Button
                        variant="contained"
                        onClick={() => handleInvestigateClick(patient.dicomLink)}
                        sx={{
                          backgroundImage: 'var(--button-primary-background)',
                          color: 'var(--text-dark)',
                          fontSize: '0.7rem',
                          fontWeight: 500,
                          marginTop: '16px',
                          padding: '0.4rem',
                          marginBottom: '16px',
                          width: '10rem',
                          borderRadius: '8px',
                          '&:hover': {
                            backgroundColor: '#3B4B4DAB',
                          },
                          '&:active': {
                            backgroundColor: '#3B4B4DAB',
                            color: 'var(--text-dark)',
                          },
                          pointerEvents: isInvestigateCaseLoading ? 'none' : 'auto',
                          opacity: isInvestigateCaseLoading ? 0.7 : 1,
                        }}
                      >
                        {isInvestigateCaseLoading ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          <>
                          Investigate Case
                          <ImageSearchIcon style={{ marginLeft: '8px' }} />
                          </>
                        )}
                      </Button>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PatientDatabase;