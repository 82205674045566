// LeftSidebar/actions.js
export const UPDATE_CHECKED = 'UPDATE_CHECKED';
export const UPDATE_IS_CHECKED = 'UPDATE_IS_CHECKED';
export const UPDATE_SLIDER_VALUE = 'UPDATE_SLIDER_VALUE';
export const UPDATE_AI_TEXT = 'UPDATE_AI_TEXT';

export const updateChecked = (checked) => ({
  type: UPDATE_CHECKED,
  payload: checked,
});

export const updateIsChecked = (isChecked) => ({
  type: UPDATE_IS_CHECKED,
  payload: isChecked,
});

export const updateSliderValue = (sliderValue) => ({
  type: UPDATE_SLIDER_VALUE,
  payload: sliderValue,
});

export const updateAiText = (aiText) => ({
  type: UPDATE_AI_TEXT,
  payload: aiText,
});