import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/General-components/Header';
import Chatbot from './components/General-components/Chatbot';
import AdvancedPreScreening from './pages/AdvancedPreScreening';
import PathologyDetection from './pages/PathologyDetection';
import ReportGen from './pages/ReportGen';
import PatientDatabase from 'pages/PatientDatabase';
import ImmersiveViewer from './pages/ImmersiveViewer';
import { ImageProvider } from './pages/ImageContext';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthPage from './components/General-components/AuthPage';
import { useSelector } from 'react-redux';

function App() {
  const { user } = useSelector((state) => state.auth);

  return (
    <Router>
      <div className="App">
        {user && <Header />}
        <ImageProvider>
          <Routes>
            <Route path="/auth" element={<AuthPage />} />
            <Route
              path="/advanced-pre-screening"
              element={user ? <AdvancedPreScreening /> : <Navigate to="/auth" />}
            />
            <Route
              path="/pathology-detection"
              element={user ? <PathologyDetection /> : <Navigate to="/auth" />}
            />
            <Route
              path="/reports-gen"
              element={user ? <ReportGen /> : <Navigate to="/auth" />}
            />
            <Route
              path="/immersive-viewer"
              element={user ? <ImmersiveViewer /> : <Navigate to="/auth" />}
            />
            <Route
              path="/patient-database"
              element={user ? <PatientDatabase /> : <Navigate to="/auth" />}
            />
            <Route path="/" element={user ? <PatientDatabase /> : <Navigate to="/auth" />} />
          </Routes>
        </ImageProvider>
        {user && <Chatbot />}
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;