// store.js
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import AdvancedPreScreeningReducer from './AdvancedPreScreening/reducers';
import LeftSidebarReducer from './LeftSidebar/reducers';
import RightSidebarReducer from './RightSidebar/reducers';
import imageViewerReducer from './ImageViewer/reducers';
import PathologyDetectionReducer from './PathologyDetection/reducers';
import PDLeftSidebarReducer from './PDLeftSidebar/reducers';
import PDRightSidebarReducer from './PDRightSidebar/reducers';
import reportGenAreaReducer from './ReportGenArea/reducers';
import patientDatabaseReducer from './PatientDatabase/reducers';
import authReducer from './auth/authReducer';


const rootReducer = combineReducers({
  AdvancedPreScreening: AdvancedPreScreeningReducer,
  LeftSidebar: LeftSidebarReducer,
  RightSidebar: RightSidebarReducer,
  imageViewer: imageViewerReducer,
  PathologyDetection: PathologyDetectionReducer,
  PDLeftSidebar: PDLeftSidebarReducer,
  PDRightSidebar: PDRightSidebarReducer,
  ReportGenArea: reportGenAreaReducer,
  PatientDatabase: patientDatabaseReducer,
  auth: authReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;