import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, Button, Typography, CircularProgress } from '@mui/material';
import { generatePDF } from 'utils/pdfGenerator';
import { updatePatientDetails, updateFindings, updateImpression, submitReport } from '../../../redux/ReportGenArea/actions';
import './ReportGenArea.css';
import InfoTooltip from '../../General-components/InfoTooltip';
import Input from '@mui/joy/Input';
import GenerateReportIcon from '@mui/icons-material/Assignment';
import GeneratePDFIcon from '@mui/icons-material/PictureAsPdf';
import CosignReportIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useImageContext } from '../../../pages/ImageContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppURLconfig from 'AppURLconfig';

const ReportGenArea = () => {
  const dispatch = useDispatch();
  const patientDetails = useSelector((state) => state.ReportGenArea.patientDetails);
  const findings = useSelector((state) => state.ReportGenArea.findings);
  const impression = useSelector((state) => state.ReportGenArea.impression);
  const { uploadedImage } = useImageContext();
  const [isGenerateReportLoading, setIsGenerateReportLoading] = useState(false);
  const [isGeneratePDFLoading, setIsGeneratePDFLoading] = useState(false);

  const handleGenerateReport = async () => {
    try {
      setIsGenerateReportLoading(true);
      const formData = new FormData();
      formData.append('file', uploadedImage);

      console.log('FormData:', formData); // Debug
      
      const response = await axios.post(`${AppURLconfig.backendUrl}/api/generate-report`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

        const report = response.data;
        console.log('Reports:', report); // Debug
        dispatch(updateFindings(report.findings));
        dispatch(updateImpression(report.impression));

    } catch (error) {
      console.error('Error generating report:', error);
    } finally {
      setIsGenerateReportLoading(false);
    }
  };

  const handleGeneratePDF = () => {
    setIsGeneratePDFLoading(true);
  
    try {
      generatePDF(patientDetails, findings, impression);
      
      // Display success notification
      toast.success('Report downloaded successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClick: () => {
          // Open the downloaded report
          window.open(`report_${patientDetails.id}.pdf`, '_blank');
        },
      });
    } catch (error) {
      // Handle error if PDF generation fails
      console.error('Error generating PDF:', error);
      toast.error('Failed to generate PDF. Please try again.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsGeneratePDFLoading(false);
    }
  };

  const handleCoSignReport = () => {
    dispatch(submitReport());
  };


  return (
    <div className="report-gen-area">
      <div className='details-section'>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <Typography variant="h3"
            sx={{ 
              fontSize: '1.1rem', 
              fontWeight: 400, 
              color: 'var(--text-primary)', 
              paddingRight: '1px',
              paddingBottom: '10px', 
              paddingLeft: '10px',
              paddingTop: '10px',
            }}
          >Patient Details</Typography>
          <InfoTooltip message="This is the patient details section. You can view and edit the patient details here." />
        </div>
        <div className="divider-line"></div>
        <div className="details-row">
          <div className="details-field">
            <label htmlFor="patientId">Patient ID:</label>
            <Input
              id="patientId"
              value={patientDetails.id}
              onChange={(e) => dispatch(updatePatientDetails({ id: e.target.value }))}
              className="patient-details-input"
              sx={{
                backgroundColor: '#E1EBDF53',
                color: 'var(--text-primary)',
              }}
              autoComplete="off"
            />
          </div>
          <div className="details-field">
            <label htmlFor="patientName">Patient Name:</label>
            <Input
              id="patientName"
              value={patientDetails.name}
              onChange={(e) => dispatch(updatePatientDetails({ name: e.target.value }))}
              className="patient-details-input"
              sx={{
                backgroundColor: '#E1EBDF53',
                color: 'var(--text-primary)',
              }}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="details-row">
          <div className="details-field">
            <label htmlFor="patientAge">Age:</label>
            <Input
              id="patientAge"
              value={patientDetails.age}
              onChange={(e) => dispatch(updatePatientDetails({ age: e.target.value }))}
              className="patient-details-input"
              sx={{
                backgroundColor: '#E1EBDF53',
                color: 'var(--text-primary)',
              }}
              autoComplete="off"
            />
          </div>
          <div className="details-field">
            <label htmlFor="patientSex">Sex:</label>
            <Input
              id="patientSex"
              value={patientDetails.sex}
              onChange={(e) => dispatch(updatePatientDetails({ sex: e.target.value }))}
              className="patient-details-input"
              sx={{
                backgroundColor: '#E1EBDF53',
                color: 'var(--text-primary)',
              }}
              autoComplete="off"
            />
          </div>
        </div>
      </div>
      <div className='gen-report-section'>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <Typography variant="h3"
            sx={{ 
              fontSize: '1.1rem', 
              fontWeight: 400, 
              color: 'var(--text-primary)', 
              paddingRight: '1px',
              paddingBottom: '10px', 
              paddingLeft: '10px',
              paddingTop: '10px',
            }}
          >Generate Report</Typography>
          <InfoTooltip message="This is the report generation section. You can view and edit the report details here." />
        </div>
        <div className="divider-line"></div>
        
        <TextField
          label="Findings"
          multiline
          rows={4}
          value={findings}
          onChange={(e) => dispatch(updateFindings(e.target.value))}
          className="report-field"
          sx={{
            '& .MuiInputLabel-root': {
              color: '#FFFFFF',
            },
            '& .MuiInputBase-input': {
              color: '#FFFFFF',
            },
            '&::before': {
              display: 'none',
              height: '10px',
            },
            '&:focus-within': {
              outline: '1.5px solid var(--nav-hover)',
              outlineOffset: '2px',
            },
            width: '100%',
            backgroundColor: '#E1EBDF53',
            marginBottom: '20px',
            borderRadius: '5px',
          }}
        />

        <TextField
          label="Impression"
          multiline
          rows={4}
          value={impression}
          onChange={(e) => dispatch(updateImpression(e.target.value))}
          className="report-field"
          sx={{
            '& .MuiInputLabel-root': {
              color: '#FFFFFF',
            },
            '& .MuiInputBase-input': {
              color: '#FFFFFF',
            },
            '&::before': {
              display: 'none',
              height: '10px',
            },
            '&:focus-within': {
              outline: '1.5px solid var(--nav-hover)',
              outlineOffset: '2px',
            },
            width: '100%',
            backgroundColor: '#E1EBDF53',
            marginBottom: '10px',
            borderRadius: '5px',
          }}
        />
      </div>
        

      <div className="action-buttons">
      <Button
          onClick={handleGenerateReport}
          variant="soft"
          size="sm"
          disabled={isGenerateReportLoading}
          sx={{
            backgroundImage: 'var(--button-primary-background)',
            color: 'var(--text-dark)',
            fontSize: '0.9rem',
            fontWeight: 500,
            padding: '0.7rem',
            width: '14rem',
            borderRadius: '18px',
            '&:hover': {
              backgroundColor: '#3B4B4DAB',
            },
            '&:active': {
              backgroundColor: '#3B4B4DAB',
              color: 'var(--text-dark)',
            },
            pointerEvents: isGenerateReportLoading ? 'none' : 'auto',
            opacity: isGenerateReportLoading ? 0.7 : 1,
          }}
        >
          {isGenerateReportLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <>
            Generate Report
            <GenerateReportIcon sx={{ marginLeft: '9px' }} />
            </>
          )}
        </Button>
        <Button
          onClick={handleGeneratePDF}    // TODO add this later on when we add the function
          variant="soft"
          size="sm"
          disabled={isGeneratePDFLoading}
          sx = {{
            backgroundImage: 'var(--button-primary-background)',
            color: 'var(--text-dark)',
            fontSize: '0.9rem',
            fontWeight: 500,
            padding: '0.7rem',
            width: '14rem',
            borderRadius: '18px',
            '&:hover': {
              backgroundColor: '#3B4B4DAB',
            },
            '&:active': {
              backgroundColor: '#3B4B4DAB',
              color: 'var(--text-dark)',
            },
            pointerEvents: isGeneratePDFLoading ? 'none' : 'auto',
            opacity: isGeneratePDFLoading ? 0.7 : 1,
          }}

        >
        {isGeneratePDFLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <>
            Generate PDF
            <GeneratePDFIcon  sx={{ marginLeft: '9px' }} />
            </>
          )}
        </Button>
        <Button
          onClick={handleCoSignReport}    // TODO add this later on when we add the function
          variant="soft"
          size="sm"
          loading={false}    // TODO add this when we have a loading state
          sx = {{
            backgroundImage: 'var(--button-primary-background)',
            color: 'var(--text-dark)',
            fontSize: '0.9rem',
            fontWeight: 500,
            padding: '0.7rem',
            width: '14rem',
            borderRadius: '18px',
            '&:hover': {
              backgroundColor: '#3B4B4DAB',
            },
            '&:active': {
              backgroundColor: '#3B4B4DAB',
              color: 'var(--text-dark)',
            },
          }}

        >Co-Sign Report
        <CosignReportIcon  sx={{ marginLeft: '9px' }} />
        </Button>
      </div>
    </div>
  );
};

export default ReportGenArea;