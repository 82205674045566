// pages/LoginForm.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../redux/auth/authActions';
import { Button, TextField, Typography, Link } from '@mui/material';
import Disclaimer from './Disclaimer';

const LoginForm = ({ onResetPassword }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);

  const handleLogin = (e) => {
    e.preventDefault();
    setShowDisclaimer(true);
  };

  const handleDisclaimerAccept = () => {
    setShowDisclaimer(false);
    dispatch(login({ email, password }))
      .unwrap()
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        if (error === 'auth/invalid-email') {
          // Display user-friendly error message for invalid email
          alert('Invalid email address');
        }
      });
  };

  const handleDisclaimerReject = () => {
    setShowDisclaimer(false);
  };

  const handleResetPassword = () => {
    onResetPassword();
  };


  return (
    <form onSubmit={handleLogin}>
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        sx={{
          '& .MuiInputBase-input': {
            color: 'white',
          },
          '& .MuiInputLabel-root': {
            color: 'white',
            '&.Mui-focused': {
              color: 'white',
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }}
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
        sx={{
          '& .MuiInputBase-input': {
            color: 'white',
          },
          '& .MuiInputLabel-root': {
            color: 'white',
            '&.Mui-focused': {
              color: 'white',
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }}
      />
      {error && (
        <Typography color="error" align="center">
          {error}
        </Typography>
      )}
      <Typography align="right" mt={2}>
        <Link href="#" onClick={handleResetPassword}
        sx = {{
          color: 'white',
          fontSize: '0.9rem',
          textDecoration: 'none',
        }}
        >
          Forgot password?
        </Link>
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={isLoading}
          sx={{
            backgroundImage: 'var(--button-primary-background)',
            color: 'var(--text-dark)',
            fontSize: '0.9rem',
            fontWeight: 500,
            padding: '0.7rem',
            width: '14rem',
            borderRadius: '18px',
            '&:hover': {
              backgroundColor: '#3B4B4DAB',
            },
            '&:active': {
              backgroundColor: '#3B4B4DAB',
              color: 'var(--text-dark)',
            },
          }}
        >
          {isLoading ? 'Loading...' : 'Login'}
        </Button>
      </div>
      <Disclaimer
        open={showDisclaimer}
        onAccept={handleDisclaimerAccept}
        onReject={handleDisclaimerReject}
      />
    </form>
  );
};

export default LoginForm;