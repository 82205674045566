import {
    SET_IMAGE_LOADED,
    SET_IMAGE_STACK,
    SET_DICOM_IMAGE,
    SET_TOOLS_ADDED,
  } from './actions';
  
  const initialState = {
    isImageLoaded: false,
    imageStack: null,
    dicomImage: null,
    toolsAdded: false,
  };
  
  const imageViewerReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_IMAGE_LOADED:
        return {
          ...state,
          isImageLoaded: action.payload,
        };
      case SET_IMAGE_STACK:
        return {
          ...state,
          imageStack: action.payload,
        };
      case SET_DICOM_IMAGE:
        return {
          ...state,
          dicomImage: action.payload,
        };
      case SET_TOOLS_ADDED:
        return { ...state, toolsAdded: action.payload };
      default:
        return state;
    }
  };
  
  export default imageViewerReducer;