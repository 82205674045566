import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleLeftSidebar, toggleRightSidebar } from '../redux/AdvancedPreScreening/actions';
import LeftSidebar from '../components/Page-Components/AdvancedPreScreening/LeftSidebar';
import ImageViewer from '../components/Page-Components/AdvancedPreScreening/ImageViewer';
import RightSidebar from '../components/Page-Components/AdvancedPreScreening/RightSidebar';
import './AdvancedPreScreening.css';
// import IconButton from '@mui/joy/IconButton';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const AdvancedPreScreening = () => {
  const dispatch = useDispatch();
  const imageViewerRef = useRef();


  
  const isLeftSidebarOpen = useSelector((state) => state.AdvancedPreScreening.isLeftSidebarOpen);
  const isRightSidebarOpen = useSelector((state) => state.AdvancedPreScreening.isRightSidebarOpen);  

  const handleToggleLeftSidebar = () => {
    dispatch(toggleLeftSidebar());
  };

  const handleToggleRightSidebar = () => {
    dispatch(toggleRightSidebar());
  };

  return (
    <div className="page-container">
      {/* <IconButton
            onClick={handleToggleLeftSidebar}
            className='left-toggle-button'
            sx={{
              position: 'absolute',
              top: '8.3rem',
              left: 0,
              transform: 'translateY(-50%)',
              zIndex: 99,
              backgroundColor: '#256F7BA8',
              color: 'white',
              borderRadius: 0,
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
            }}
          >
            {isLeftSidebarOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
          </IconButton> */}
      {isLeftSidebarOpen && (
        <LeftSidebar
          className={`left-sidebar ${isLeftSidebarOpen ? 'open' : 'closed'}`}
          onToggle={handleToggleLeftSidebar}
        />
      )}
      <ImageViewer
        ref={imageViewerRef}
        className={`image-viewer ${isLeftSidebarOpen || isRightSidebarOpen ? 'expand' : ''}`}
        isLeftSidebarOpen={isLeftSidebarOpen}
        isRightSidebarOpen={isRightSidebarOpen}
      />
      {/* <IconButton
            onClick={handleToggleRightSidebar}
            className='right-toggle-button'
            sx={{
              position: 'absolute',
              top: '8.3rem',
              right: 0,
              transform: 'translateY(-50%)',
              zIndex: 99,
              backgroundColor: '#256F7BA8',
              color: 'white',
              borderRadius: 0,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
            }}
          >
            {isRightSidebarOpen ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
          </IconButton> */}
      {isRightSidebarOpen && (
        <RightSidebar
          className={`right-sidebar ${isRightSidebarOpen ? 'open' : 'closed'}`}
          onToggle={handleToggleRightSidebar}
          onContrastChange={(contrast) => {
            if (imageViewerRef.current) {
              imageViewerRef.current.updateWindowLevel(contrast, undefined, undefined);
            }
          }}
          onBrightnessChange={(brightness) => {
            if (imageViewerRef.current) {
              imageViewerRef.current.updateWindowLevel(undefined, brightness, undefined);
            }
          }}
          onThresholdChange={(threshold) => {
            if (imageViewerRef.current) {
              imageViewerRef.current.updateWindowLevel(undefined, undefined, threshold);
            }
          }}
        />
      )}
    </div>
  );
};

export default AdvancedPreScreening;