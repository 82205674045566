import { ToolGroupManager } from '@cornerstonejs/tools';

const disableAllTools = () => {
  const toolGroup = ToolGroupManager.getToolGroup('MY_TOOL_GROUP_ID');
  if (toolGroup) {
    toolGroup.setToolPassive('Pan');
    toolGroup.setToolPassive('Zoom');
    toolGroup.setToolPassive('Length');
    toolGroup.setToolPassive('EllipticalROI');
    toolGroup.setToolPassive('RectangleROI');
    toolGroup.setToolPassive('Angle');
    toolGroup.setToolPassive('Probe');
    toolGroup.setToolPassive('Magnify');
    // toolGroup.setToolPassive('Eraser');
  }
};

export default disableAllTools;