import React from 'react';
import Checkbox from '@mui/joy/Checkbox';
// import { extendTheme, CssVarsProvider } from '@mui/joy/styles';

// TODO: fix the white background issue in checkbox

// const theme = extendTheme({
//   components: {
//     JoySheet: {
//       styleOverrides: {
//         root: {
//           bgcolor: 'transparent',
//           color: 'black',
//           borderRadius: '1px',
//           '&:hover': {
//             bgcolor: '#25707b',
//           },
//         },
//       },
//     },
//   },
// });

const CustomCheckbox = ({ label, value, checked, onChange }) => {
  return (
    // <CssVarsProvider theme={theme}>
    <label style={{ display: 'inline-flex', alignItems: 'center', paddingBottom: '5px'}}>
      <Checkbox 
          color="neutral"
          variant="plain"
          checked={checked}
          onChange={(event) => onChange(event, value)}

          sx={{
            '& :not(.Mui-checked)': { 
              bgcolor: 'transparent', 
              color: 'black',
              border: '1.5px solid var(--primary-white)',
              borderRadius: '5px',

              '&:hover': {
                bgcolor: 'var(--primary-white)',
              },
              
            },

          }}

      />
        <span style={{ marginLeft: '5px' }}>{label}</span>  
      </label>
      // </CssVarsProvider>
  );
};

export default CustomCheckbox;
