// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAQhRGinNIeG0tlqViORfyDj2Ae3oz8Cy4",
    authDomain: "radvision-8ca0d.firebaseapp.com",
    projectId: "radvision-8ca0d",
    storageBucket: "radvision-8ca0d.appspot.com",
    messagingSenderId: "443889939042",
    appId: "1:443889939042:web:7c8d13f28b47d04dd4d73b",
    measurementId: "G-YKQNP5E3CL"
  };

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);

export { auth };