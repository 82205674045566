// advancedPreScreening/reducers.js
import { TOGGLE_LEFT_SIDEBAR, TOGGLE_RIGHT_SIDEBAR } from './actions';

const initialState = {
  isLeftSidebarOpen: true,
  isRightSidebarOpen: true,
};

const AdvancedPreScreeningReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LEFT_SIDEBAR:
      return {
        ...state,
        isLeftSidebarOpen: !state.isLeftSidebarOpen,
      };
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        isRightSidebarOpen: !state.isRightSidebarOpen,
      };
    default:
      return state;
  }
};

export default AdvancedPreScreeningReducer;