import React from 'react';
import { useDropzone } from 'react-dropzone';
import XrayImage from '../../assets/xray_dropzone_white.png';
import { Button } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';

const DicomDropzone = ({ onFileDrop }) => {
  const onDrop = (acceptedFiles) => {
    onFileDrop(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the DICOM file here...</p>
      ) : (
        <section>
          <img src={XrayImage} alt="DICOM File Upload" style={{ width: '100px', marginBottom: '10px' }} />
          <p className="drag-and-drop-text">Drag and drop files</p>
          <Button 
          className="upload-button"
          variant="soft"
          size="sm"
          loading={false}
          sx={{
            backgroundImage: 'var(--button-primary-background)',
            color: 'var(--text-dark)',
            fontSize: '0.9rem',
            fontWeight: 500,
            padding: '0.7rem',
            width: '14rem',
            borderRadius: '18px',
            '&:hover': {
              backgroundColor: '#3B4B4DAB',
            },
            '&:active': {
              backgroundColor: '#3B4B4DAB',
              color: 'var(--text-dark)',
            },
          }}
        >
            Upload Files
            <UploadIcon />
          </Button>
        </section>
      )}
    </div>
  );
};

export default DicomDropzone;