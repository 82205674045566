import React, { useState, useEffect } from 'react';
import Slider from '@mui/joy/Slider';

const CustomSlider = ({ ranges, initialValue, marks,  onChange }) => {
  const [value, setValue] = useState(initialValue || ranges[0]); // Initialize state

  useEffect(() => {
    // Reset value to the first range if it falls outside the provided ranges
    if (value < ranges[0] || value > ranges[ranges.length - 1]) {
      setValue(ranges[0]);
    }
  }, [ranges, value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue); // Notify parent component of change
  };

  return (
    <Slider
      min={ranges[0]}
      max={ranges[ranges.length - 1]}
      value={value}
      onChange={handleChange}
      valueLabelDisplay="auto"
     marks={marks}
      sx={{
        "--Slider-trackSize": "10px",
        "--Slider-markSize": "5px",
        "--Slider-thumbWidth": "23px",
        "--Slider-thumbSize": "19px",
        "--Slider-valueLabelArrowSize": "8px",
        "--Slider-thumbColor": "var(--slider-thumb-border)",
      
        // Track color (the line)
        '& .MuiSlider-track': {
          backgroundColor: 'var(--slider-track)', // Using the primary color from your palette
        },
        // Thumb color (the draggable circle)
        '& .MuiSlider-thumb': {
          backgroundColor: 'var(--primary-white)', // Using the primary white color from your palette
        },    
        '& .MuiSlider-valueLabel': {
          backgroundColor: 'var(--text-dark)', // Using the dark text color from your palette
          color: 'var(--text-primary)', // Using the primary text color from your palette
          boxShadow: '0 1px 7px var(--shadow-color)', // Using the shadow color from your palette
          fontSize: '13px',
          padding: '3px',
        },
        '& .MuiSlider-markLabel': {
          color: 'var(--text-primary)', // Using the primary text color from your palette
        },
        '& .MuiSlider-mark': {
          backgroundColor: 'var(--secondary-white)', // Using the secondary white color from your palette
        },
      }}
    />
  );
};

export default CustomSlider;
