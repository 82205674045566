import React, { useEffect, useRef } from 'react';
import { RenderingEngine, Enums, init as csCoreInit } from '@cornerstonejs/core';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import dicomParser from 'dicom-parser';
import * as cornerstone from '@cornerstonejs/core';
import { convertMultiframeImageIds } from 'utils/convertMultiframeImageIds';
import Typography from '@mui/material/Typography';
import AppURLconfig from 'AppURLconfig';

const { ViewportType } = Enums;

const DicomViewportGrid = () => {
  const viewportRefs = useRef([]);

  useEffect(() => {
    const initializeViewports = async () => {
      await csCoreInit();
      cornerstoneDICOMImageLoader.external.cornerstone = cornerstone;
      cornerstoneDICOMImageLoader.external.dicomParser = dicomParser;
      cornerstoneDICOMImageLoader.configure({
        useWebWorkers: true,
        decodeConfig: {
          convertFloatPixelDataToInt: false,
          use16BitDataType: false,
        },
      });

      let maxWebWorkers = 1;

      if (navigator.hardwareConcurrency) {
        maxWebWorkers = Math.min(navigator.hardwareConcurrency, 7);
      }

      var config = {
        maxWebWorkers,
        startWebWorkersOnDemand: false,
        taskConfiguration: {
          decodeTask: {
            initializeCodecsOnStartup: false,
            strict: false,
          },
        },
      };

      cornerstoneDICOMImageLoader.webWorkerManager.initialize(config);

      const renderingEngineId = 'myRenderingEngine';
      const renderingEngine = new RenderingEngine(renderingEngineId);

      const viewportInputs = [
        {
          viewportId: 'frontalViewport',
          type: ViewportType.STACK,
          element: viewportRefs.current[0],
        },
        {
          viewportId: 'lateralViewport',
          type: ViewportType.STACK,
          element: viewportRefs.current[1],
        },
      ];

      viewportInputs.forEach((viewportInput) => {
        renderingEngine.enableElement(viewportInput);
      });

      const frontalViewport = renderingEngine.getViewport('frontalViewport');
      const lateralViewport = renderingEngine.getViewport('lateralViewport');

      const loadDicomImage = async (viewport, dicomUrl) => {
        try {
          const response = await fetch(dicomUrl);
          const blob = await response.blob();
          const file = new File([blob], 'dicom.dcm', { type: 'application/dicom' });
          const imageId = cornerstoneDICOMImageLoader.wadouri.fileManager.add(file);
          const stack = convertMultiframeImageIds([imageId]);
          viewport.setStack(stack).then(() => {
            viewport.render();
          });
        } catch (error) {
          console.error('Error loading DICOM image:', error);
        }
      };

      loadDicomImage(frontalViewport, `${AppURLconfig.backendUrl}/api/frontalview.dcm`);
      loadDicomImage(lateralViewport, `${AppURLconfig.backendUrl}/api/lateralview.dcm`);
    };

    initializeViewports();
  }, []);

  return (
    <div className="dicom-viewports-container">
        <div className="dicom-viewport">
          <Typography variant="h6" component="div" align="center" style={{ color: 'white' }}>
            Frontal View
          </Typography>
          <div
            ref={(el) => (viewportRefs.current[0] = el)}
            style={{ width: '100%', height: '400px' }}
          ></div>
        </div>
        <div className="dicom-viewport">
          <Typography variant="h6" component="div" align="center" style={{ color: 'white' }}>
            Lateral View
          </Typography>
          <div
            ref={(el) => (viewportRefs.current[1] = el)}
            style={{ width: '100%', height: '400px' }}
          ></div>
        </div>
      </div>
  );
};

export default DicomViewportGrid;