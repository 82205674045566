// RightSidebar/actions.js
export const UPDATE_GAMMA_CORRECTION_CHECKED = 'UPDATE_GAMMA_CORRECTION_CHECKED';
export const UPDATE_NOISE_REDUCTION_CHECKED = 'UPDATE_NOISE_REDUCTION_CHECKED';
export const UPDATE_EDGE_ENHANCEMENT_CHECKED = 'UPDATE_EDGE_ENHANCEMENT_CHECKED';
export const UPDATE_CONTRAST_SLIDER_VALUE = 'UPDATE_CONTRAST_SLIDER_VALUE';
export const UPDATE_BRIGHTNESS_SLIDER_VALUE = 'UPDATE_BRIGHTNESS_SLIDER_VALUE';
export const UPDATE_THRESHOLD_SLIDER_VALUE = 'UPDATE_THRESHOLD_SLIDER_VALUE';

export const updateGammaCorrectionChecked = (checked) => ({
  type: UPDATE_GAMMA_CORRECTION_CHECKED,
  payload: checked,
});

export const updateNoiseReductionChecked = (checked) => ({
  type: UPDATE_NOISE_REDUCTION_CHECKED,
  payload: checked,
});

export const updateEdgeEnhancementChecked = (checked) => ({
  type: UPDATE_EDGE_ENHANCEMENT_CHECKED,
  payload: checked,
});

export const updateContrastSliderValue = (value) => ({
  type: UPDATE_CONTRAST_SLIDER_VALUE,
  payload: value,
});

export const updateBrightnessSliderValue = (value) => ({
  type: UPDATE_BRIGHTNESS_SLIDER_VALUE,
  payload: value,
});

export const updateThresholdSliderValue = (value) => ({
  type: UPDATE_THRESHOLD_SLIDER_VALUE,
  payload: value,
});