export const UPDATE_PATIENT_DETAILS = 'UPDATE_PATIENT_DETAILS';
export const UPDATE_FINDINGS = 'UPDATE_FINDINGS';
export const UPDATE_IMPRESSION = 'UPDATE_IMPRESSION';
export const SUBMIT_REPORT = 'SUBMIT_REPORT';

export const updatePatientDetails = (patientDetails) => ({
  type: UPDATE_PATIENT_DETAILS,
  payload: patientDetails,
});

export const updateFindings = (findings) => ({
  type: UPDATE_FINDINGS,
  payload: findings,
});

export const updateImpression = (impression) => ({
  type: UPDATE_IMPRESSION,
  payload: impression,
});

export const submitReport = () => ({
  type: SUBMIT_REPORT,
});