// pages/SignupForm.js
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signup } from '../../redux/auth/authActions';
import { Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Disclaimer from './Disclaimer';

const SignupForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [profession, setProfession] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const handleSignup = (e) => {
    e.preventDefault();
    setShowDisclaimer(true);
  };

  const handleDisclaimerAccept = () => {
    setShowDisclaimer(false);
    dispatch(signup({ email, password, profession }))
      .unwrap()
      .then(() => {
        navigate('/');
      });
  };

  const handleDisclaimerReject = () => {
    setShowDisclaimer(false);
  };


  return (
    <form onSubmit={handleSignup}>
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        sx={{
          '& .MuiInputBase-input': {
            color: 'white',
          },
          '& .MuiInputLabel-root': {
            color: 'white',
            '&.Mui-focused': {
              color: 'white',
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }}
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
        sx={{
          '& .MuiInputBase-input': {
            color: 'white',
          },
          '& .MuiInputLabel-root': {
            color: 'white',
            '&.Mui-focused': {
              color: 'white',
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }}
      />
      {error && (
        <Typography color="error" align="center">
          {error}
        </Typography>
      )}
      <FormControl fullWidth margin="normal">
        <InputLabel
          sx={{
            color: 'white',
            '&.Mui-focused': {
              color: 'white',
            },
          }}
        >
          Profession
        </InputLabel>
        <Select
          value={profession}
          onChange={(e) => setProfession(e.target.value)}
          required
          sx={{
            '& .MuiSelect-icon': {
              color: 'white',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white',
            },
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            color: 'white',
          }}
        >
          <MenuItem value="">Select profession</MenuItem>
          <MenuItem value="radiologist">Radiologist</MenuItem>
          <MenuItem value="it">IT / Software Developer</MenuItem>
          <MenuItem value="doctor">Doctor</MenuItem>
          {/* Add more profession options */}
        </Select>
      </FormControl>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
      <Button type="submit" variant="contained" color="primary" fullWidth disabled={isLoading}
      sx={{
        backgroundImage: 'var(--button-primary-background)',
        color: 'var(--text-dark)',
        fontSize: '0.9rem',
        fontWeight: 500,
        padding: '0.7rem',
        width: '14rem',
        borderRadius: '18px',
        '&:hover': {
          backgroundColor: '#3B4B4DAB',
        },
        '&:active': {
          backgroundColor: '#3B4B4DAB',
          color: 'var(--text-dark)',
        },
      }}
      >
        {isLoading ? 'Loading...' : 'Signup'}
      </Button>
      </div>
      <Disclaimer
        open={showDisclaimer}
        onAccept={handleDisclaimerAccept}
        onReject={handleDisclaimerReject}
      />
    </form>
  );
};

export default SignupForm;