import React from 'react';
import Typography from '@mui/joy/Typography'; 
import CustomSwitch from '../../General-components/CustomSwitch';
import InfoTooltip  from '../../General-components/InfoTooltip';
import CustomSlider from '../../General-components/CustomSlider';
import Button from '@mui/joy/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateGammaCorrectionChecked,
  updateNoiseReductionChecked,
  updateEdgeEnhancementChecked,
  updateContrastSliderValue,
  updateBrightnessSliderValue,
  updateThresholdSliderValue,
} from '../../../redux/RightSidebar/actions';

const RightSidebar = ({ className, onContrastChange, onBrightnessChange, onThresholdChange }) => {

  const dispatch = useDispatch();
  const {
    gammaCorrectionChecked,
    noiseReductionChecked,
    edgeEnhancementChecked,
    contrastSliderValue,
    brightnessSliderValue,
    thresholdSliderValue,
  } = useSelector((state) => state.RightSidebar);
  /* Gamma Correction switch state setup ---------------*/
  const handleGammaCorrectionChange = (event) => {
    dispatch(updateGammaCorrectionChecked(event.target.checked));
  };
  /* Noise Reduction switch state setup ---------------*/
  const handleNoiseReductionChange = (event) => {
    dispatch(updateNoiseReductionChecked(event.target.checked));
  };
  /* Edge enhancement switch state setup ---------------*/
  const handleEdgeEnhancementChange = (event) => {
    dispatch(updateEdgeEnhancementChecked(event.target.checked));
  };
  
  

  /*Contrast slider setup --------------*/
  // ranges go from 0 to 99 in steps of 1
  const contrastRanges = { min: 1, max: 100, step: 1 };
  const handleContrastSliderValue = (newValue) => {
    dispatch(updateContrastSliderValue(newValue));
    onContrastChange(newValue);
  };
  /* Brightness slider setup --------------*/
  // ranges go from 0 to 99 in steps of 1
  const brightnessRanges = { min: 1, max: 100, step: 1 };
  const handleBrightnessSliderValue = (newValue) => {
    dispatch(updateBrightnessSliderValue(newValue));
    onBrightnessChange(newValue);
  };
  /* Threshold slider setup --------------*/
  // ranges go from 0 to 99 in steps of 1
  const thresholdRanges = { min: 1, max: 100, step: 1 };
  const handleThresholdSliderValue = (newValue) => {
    dispatch(updateThresholdSliderValue(newValue));
    onThresholdChange(newValue);
  };



  /* Apply viewer changes Button onClick function --------------------------*/
  // const handleApplyViewerchanges = () => {
  //     // Your logic here
  // };


  return (
        <div className={className}>
          <div className='contrast-slider'>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Typography
                      variant="h3"
                      sx={{ 
                        fontSize: '1.1rem', 
                        fontWeight: 400, 
                        color: 'var(--text-primary)', 
                        paddingRight: '1px',
                        paddingBottom: '10px', 
                        paddingLeft: '10px',
                        paddingTop: '10px',
                      }}
                  > Contrast
                  </Typography>
                  <InfoTooltip message="Contrast is the difference in brightness between objects or regions. Increasing the contrast will make the image more vivid." />
                </div>
            <div className='slider'>
                  <CustomSlider 
                      ranges={contrastRanges}
                      initialValue={contrastSliderValue}
                      marks={[{ value: 1, label: '1' }, { value: 100, label: '100' }]} 
                      onChange={handleContrastSliderValue}
                  />
            </div>
          </div>
          <div className='brightness-slider'>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Typography
                      variant="h3"
                      sx={{ 
                        fontSize: '1.1rem', 
                        fontWeight: 400, 
                        color: 'var(--text-primary)', 
                        paddingRight: '1px',
                        paddingBottom: '10px', 
                        paddingLeft: '10px',
                        paddingTop: '10px',
                      }}
                  > Brightness
                  </Typography>
                  <InfoTooltip message="Brightness is the overall lightness or darkness of the image. Increasing the brightness will make the image lighter." />
                </div>
            <div className='slider'>
                  <CustomSlider 
                      ranges={brightnessRanges} 
                      initialValue={brightnessSliderValue}
                      marks={[{ value: 1, label: '1' }, { value: 100, label: '100' }]} 
                      onChange={handleBrightnessSliderValue}
                  />
            </div>
          </div>
          <div className='threshold-slider'>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Typography
                      variant="h3"
                      sx={{ 
                        fontSize: '1.1rem', 
                        fontWeight: 400, 
                        color: 'var(--text-primary)', 
                        paddingRight: '1px',
                        paddingBottom: '10px', 
                        paddingLeft: '10px',
                        paddingTop: '10px',
                      }}
                  > Threshold
                  </Typography>
                  <InfoTooltip message="Thresholding is the simplest method of image segmentation. It is used to create binary images." />
                </div>
            <div className='slider'>
                  <CustomSlider 
                      ranges={thresholdRanges} 
                      initialValue={thresholdSliderValue}
                      marks={[{ value: 1, label: '1' }, { value: 100, label: '100' }]} 
                      onChange={handleThresholdSliderValue}
                  />
            </div>
          </div>
          <div className='right-sidebar-switches'>
          <Typography 
                    component="label" 
                    endDecorator={
                        <CustomSwitch 
                            initialState={gammaCorrectionChecked}
                            onSwitchChange={handleGammaCorrectionChange}
                        />} 
                    sx={{ 
                      fontSize: '0.9rem', 
                      fontWeight: 400, 
                      color: 'var(--text-primary)', 
                      padding: '10px 20px 10px 20px', 
                      display: 'flex',  
                      alignItems: 'center', 
                      justifyContent: 'space-between'
                  }}
                    >
                    Gamma Correction
              </Typography>
              <Typography 
                    component="label" 
                    endDecorator={
                        <CustomSwitch 
                            initialState={noiseReductionChecked}
                            onSwitchChange={handleNoiseReductionChange}
                        />} 
                    sx={{ 
                      fontSize: '0.9rem', 
                      fontWeight: 400, 
                      color: 'var(--text-primary)', 
                      padding: '10px 20px 10px 20px',
                      display: 'flex',  
                      alignItems: 'center', 
                      justifyContent: 'space-between'
                  }}
                    >
                    Noise Reduction
              </Typography>
              <Typography 
                    component="label" 
                    endDecorator={
                        <CustomSwitch 
                            initialState={edgeEnhancementChecked}
                            onSwitchChange={handleEdgeEnhancementChange}
                        />} 
                    sx={{ 
                      fontSize: '0.9rem', 
                      fontWeight: 400, 
                      color: 'var(--text-primary)', 
                      padding: '10px 20px 10px 20px', 
                      display: 'flex',  
                      alignItems: 'center', 
                      justifyContent: 'space-between'
                  }}
                    >
                    Edge Enhancement
              </Typography>
              </div>
              <div className='start-prescreening-btn'>
              <Button
                // onClick={handleApplyViewerchanges}
                variant="soft"
                size="sm"
                startDecorator={<CheckCircleOutlineIcon />}
                loading={false}    // TODO add this when we have a loading state
                sx = {{
                  backgroundImage: 'var(--button-primary-background)',
                  color: 'var(--text-dark)',
                  fontSize: '0.9rem',
                  fontWeight: 500,
                  marginTop: '40px',
                  padding: '0.7rem',
                  width: '14rem',
                  borderRadius: '18px',
                  '&:hover': {
                    backgroundColor: '#3B4B4DAB',
                  },
                  '&:active': {
                    backgroundColor: '#3B4B4DAB',
                    color: 'var(--text-dark)',
                  },
                }}

              >Apply Viewer Changes</Button>
              </div>




          </div>
  );
}

export default RightSidebar;
