import React, { useState } from 'react';
import Typography from '@mui/joy/Typography'; 
import InfoTooltip  from '../../General-components/InfoTooltip';
import { Button, CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateAnatomySelected,
  updateHighlightAnatomyClicked,
  updateHighlightPathologyClicked,
  updateSelectedAnatomyURL,
  updateSegmentationData,
  updateBoundingBoxData,
} from '../../../redux/PDLeftSidebar/actions';
import axios from 'axios';
import { Select, Option, styled } from '@mui/joy';
import { useImageContext } from '../../../pages/ImageContext';
import ArrowIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ReportIcon from '@mui/icons-material/Article';
import AppURLconfig from 'AppURLconfig';

const PDLeftSidebar = ({ className }) => {
  const dispatch = useDispatch();
  const { anatomySelected,
          highlightAnatomyClicked,
          highlightPathologyClicked,
          segmentationData,
          boundingBoxData,
        } = useSelector((state) => state.PDLeftSidebar);

  const [isAnatomyLoading, setIsAnatomyLoading] = useState(false);
  const [isPathologyLoading, setIsPathologyLoading] = useState(false);
  const { uploadedImage } = useImageContext();
  /* Anatomy selector state setup ---------------------------------*/
  const handleAnatomySelectChange = (event, value) => {
    dispatch(updateAnatomySelected(value));
  };

  const StyledSelect = styled(Select)(({ theme }) => ({
    backgroundColor: 'transparent',
    border: '2px solid #25707b',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(76, 175, 80, 0.1)',
    },
    '&.Mui-focused': {
      borderColor: '#25707b',
      boxShadow: '0 0 0 2px rgba(76, 175, 80, 0.2)',
    },
    '& .MuiSelect-indicator': {
      color: '#25707b',
    },
  }));
  
  const StyledOption = styled(Option)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: 'rgba(76, 175, 80, 0.1)',
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(76, 175, 80, 0.2)',
    },
  }));

  /* Pathology selector state setup ---------------------------------*/


  /* Highlight Anatomy switch state setup ---------------*/
  // useEffect(() => {
  //   if (anatomySelected && segmentationData) {
  //     const selectedAnatomyURL = segmentationData[anatomySelected];
  //     console.log('Selected anatomy URL update in pdfleftsidebar:', selectedAnatomyURL); // Debug
  //     dispatch(updateSelectedAnatomyURL(selectedAnatomyURL));
  //   }
  // }, [anatomySelected, segmentationData, dispatch]);

  // -----------------------------------------------------
  const handleHighlightAnatomyClick = async () => {
    dispatch(updateHighlightAnatomyClicked());

    if (!highlightAnatomyClicked) {
      try {
        setIsAnatomyLoading(true);
        console.log('Uploaded image segmentation:', uploadedImage); // Debug

        // Create a new FormData object
        const formData = new FormData();
        formData.append('file', uploadedImage);

        console.log('segmentation FormData:', formData); // Debug
        
        const response = await axios.post(`${AppURLconfig.backendUrl}/api/segmentation-dicom-contour`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        const segmentationData = response.data;
        console.log('Segmentation data update segmentation:', segmentationData); // Debug
        dispatch(updateSegmentationData(segmentationData));
        if (anatomySelected && segmentationData) {
          const selectedAnatomyURL = segmentationData[anatomySelected];
          console.log('Selected anatomy URL update in pdfleftsidebar:', selectedAnatomyURL); // Debug
          dispatch(updateSelectedAnatomyURL(selectedAnatomyURL));
        }
      } catch (error) {
        console.error('Error generating report:', error);
        // Handle error state or display an error message
      } finally {
        setIsAnatomyLoading(false);
      }
    }
  };

  /* Pathologies Detected btn function call ---------------*/
  // -----------------------------------------------------
  const handleHighlightPathologyClick = async () => {
    dispatch(updateHighlightPathologyClicked());

    if (!highlightPathologyClicked) {
      try {
        setIsPathologyLoading(true);
        console.log('Uploaded image boundingbox one:', uploadedImage); // Debug

        // Create a new FormData object
        const formData = new FormData();
        formData.append('file', uploadedImage);

        console.log('bbox FormData:', formData); // Debug
        
        const response = await axios.post(`${AppURLconfig.backendUrl}/api/v2/box`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('bbox Response:', response); // Debug
        const responseData = response.data;
        const boundingBoxData = responseData.dicomUrl;
        const diseasesList = responseData.diseases;
        dispatch(updateBoundingBoxData({ URL: boundingBoxData, diseaseList: diseasesList }));
      } catch (error) {
        console.error('Error generating report:', error);
        // Handle error state or display an error message
      } finally {
        setIsPathologyLoading(false);
      }
    }
  };

  /* Button onClick function --------------------------*/
  const handleGenerateReport = () => {
    // Logic for generating the report
  };

  return (
    <div className={className}>
      <div className='anatomy-group'>
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <Typography
            variant="h3"
            sx={{ 
              fontSize: '1.1rem', 
              fontWeight: 400, 
              color: 'var(--text-primary)', 
              paddingRight: '1px',
              paddingBottom: '10px', 
              paddingLeft: '10px',
              paddingTop: '10px',
            }}
          > Anatomy Selector
          </Typography>
          <InfoTooltip message="Select anatomical regions of interest for analysis" />
        </div>
        <div className='anatomy-selector-divider'></div>
        <div className='anatomy-selector--col'>
        <StyledSelect
          value={anatomySelected}
          onChange={handleAnatomySelectChange}
          sx = {{
            color: 'var(--text-primary)',
            border: '2px solid #CDDECE',
          }}
        >
          <StyledOption value="" disabled>Select an anatomy</StyledOption>
          <StyledOption value="Aorta">Aorta</StyledOption>
          <StyledOption value="Facies Diaphragmatica">Facies Diaphragmatica</StyledOption>
          <StyledOption value="Heart">Heart</StyledOption>
          <StyledOption value="Left Clavicle">Left Clavicle</StyledOption>
          <StyledOption value="Left Hilus Pulmonis">Left Hilus Pulmonis</StyledOption>
          <StyledOption value="Left Lung">Left Lung</StyledOption>
          <StyledOption value="Right Clavicle">Right Clavicle</StyledOption>
          <StyledOption value="Right Hilus Pulmonis">Right Hilus Pulmonis</StyledOption>
          <StyledOption value="Right Lung">Right Lung</StyledOption>
          <StyledOption value="Right Scapula">Right Scapula</StyledOption>
          <StyledOption value="Spine">Spine</StyledOption>
          <StyledOption value="Weasand">Weasand</StyledOption>
        </StyledSelect>
        </div>
      </div>
      <div className='highlight-anatomy-btn-div'>
      <Button
          variant='soft'
          onClick={handleHighlightAnatomyClick}
          disabled={isAnatomyLoading}
          sx={{
            backgroundImage: 'var(--button-primary-background)',
            color: 'var(--text-dark)',
            fontSize: '0.7rem',
            fontWeight: 500,
            padding: '0.4rem',
            width: '12rem',
            borderRadius: '8px',
            margin: 'auto',
            '&:hover': {
              backgroundColor: '#3B4B4DAB',
            },
            '&:active': {
              backgroundColor: '#3B4B4DAB',
              color: 'var(--text-dark)',
            },
            pointerEvents: isAnatomyLoading ? 'none' : 'auto',
            opacity: isAnatomyLoading ? 0.7 : 1,
          }}
        >
          {isAnatomyLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <>
            Highlight Anatomy
            <ArrowIcon />
            </>
          )}
        </Button>
      </div>
      <div className='pathology-list-group'>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <Typography 
            variant="h3" 
            sx={{ 
              fontSize: '1.1rem', 
              fontWeight: 400, 
              color: 'var(--text-primary)', 
              paddingRight: '1px',
              paddingBottom: '10px', 
              paddingLeft: '10px',
              paddingTop: '10px',
            }}
            >
              Pathology List
            </Typography>
            <InfoTooltip message="List of detected pathologies in the shown dicom image" />
           </div>  
      {boundingBoxData.diseaseList.length > 0 && (    
        <div className="pathology-list">
          <div className='anatomy-selector-divider'></div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <div 
          className='pathology-list--col'
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.5rem',
            padding: '0.5rem',
          }}
          >
            {boundingBoxData.diseaseList.map((disease, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: '#BFDAD3F8',
                  borderRadius: '17px',
                  padding: '0.6rem',
                  color: 'var(--text-dark)',
                  fontSize: '0.9rem',
                  fontWeight: 400,
                }}
              >
                {disease}
              </div>
            ))}
            </div>
        </div>
        </div>
      )}
      </div>
      <div className='pathology-btn-div'>
      <Button
          variant='soft'
          onClick={handleHighlightPathologyClick}
          disabled={isPathologyLoading}
          sx={{
            backgroundImage: 'var(--button-primary-background)',
            color: 'var(--text-dark)',
            fontSize: '0.7rem',
            fontWeight: 500,
            padding: '0.4rem',
            width: '12rem',
            borderRadius: '8px',
            margin: 'auto',
            '&:hover': {
              backgroundColor: '#3B4B4DAB',
            },
            '&:active': {
              backgroundColor: '#3B4B4DAB',
              color: 'var(--text-dark)',
            },
            pointerEvents: isPathologyLoading ? 'none' : 'auto',
            opacity: isPathologyLoading ? 0.7 : 1,
          }}
        >
          {isPathologyLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <>
            Highlight Pathology
            <ArrowIcon />
            </>
          )}
        </Button>
      </div>
      <div className='generate-report-btn-wrapper'>
        <div className='generate-report-btn'>
          <Button
            onClick={handleGenerateReport}
            variant="soft"
            size="sm"
            loading={false}
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              backgroundImage: 'var(--button-primary-background)',
              color: 'var(--text-dark)',
              fontSize: '0.9rem',
              fontWeight: 550,
              padding: '0.7rem',
              width: '14rem',
              borderRadius: '18px',
              '&:hover': {
                backgroundColor: '#3B4B4DAB',
              },
              '&:active': {
                backgroundColor: '#3B4B4DAB',
                color: 'var(--text-dark)',
              },
            }}
          >
            Generate Report
            <ReportIcon 
              sx={{
                marginLeft: '0.5rem',
              }}
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PDLeftSidebar;