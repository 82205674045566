import React, { useRef } from 'react';
import ImageViewer from '../components/Page-Components/AdvancedPreScreening/ImageViewer';
import ReportGenArea from '../components/Page-Components/ReportGen/ReportGenArea';
import './ReportGen.css';

const ReportGen = () => {
  const imageViewerRef = useRef();


  return (
    <div className="reportgen-container">
    <div className="report-gen-image-viewer-container">
      <ImageViewer
        ref={imageViewerRef}
        className='image-viewer'
      />
    </div>
      <ReportGenArea />
    </div>
  );
};

export default ReportGen;