import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, IconButton, TextField, Typography, Fade, CircularProgress, Avatar } from '@mui/material';
import ChatIcon from '@mui/icons-material/MarkChatUnread';
import SendIcon from '@mui/icons-material/Send';
import logo from '../../assets/logo_white.png';
import AppURLconfig from 'AppURLconfig';

const Chatbot = () => {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    startSession();
    console.log('sessionId should always run:', sessionId);
  }, []);

  const startSession = async () => {
    try {
      const response = await axios.get(`${AppURLconfig.backendUrl}/api/startSession`);
      console.log('response start:', response);
      setSessionId(response.data.session_id);
    } catch (error) {
      console.error('Error starting session:', error);
    }
  };
  
  const sendMessage = async () => {
    if (inputValue.trim() !== '') {
      console.log('inputValue:', inputValue);
      console.log('sessionId:', sessionId);
      setLoading(true);
      try {
        const response = await axios.post(`${AppURLconfig.backendUrl}/api/sendMessage`, {
          session_id: sessionId,
          query: inputValue,
          current_case_info: 'You are a knowledgeable medical assistant, keep your responses short and stick to the case facts that are presented to you. This is a case study which you would help with - A 65-year-old retired Asian American construction worker presents with a persistent cough of 6 months duration, accompanied by yellowish sputum. He also reports shortness of breath with exertion, unintentional weight loss, and night sweats. His history is notable for being a former smoker and potential asbestos exposure from his construction work. Additionally, he traveled to Southeast Asia a year ago.',
          precedent_analysis_context: '',
        });
        console.log('response:', response);
        const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        setMessages((prevMessages) => [...prevMessages, { text: inputValue, sender: 'user', timestamp: currentTime }]);
        setMessages((prevMessages) => [...prevMessages, { text: response.data.response, sender: 'bot', timestamp: currentTime }]);
        setInputValue('');
      } catch (error) {
        console.error('Error sending message:', error);
      }
      setLoading(false);
    }
  };

  const endSession = async () => {
    try {
      await axios.get(`/endSession/${sessionId}`);
      setSessionId('');
      setMessages([]);
    } catch (error) {
      console.error('Error ending session:', error);
    }
  };

  return (
    <>
      <IconButton
        color="primary"
        onClick={() => setOpen(!open)}
        sx={{ 
          color: 'var(--primary-white)',
          border: '2px solid var(--primary-white)',
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
      >
        <ChatIcon />
      </IconButton>
      {open && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 80,
            right: 16,
            width: 300,
            height: 500,
            backgroundColor: 'white',
            borderRadius: 4,
            boxShadow: 3,
            display: 'flex',
            flexDirection: 'column',
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#3B4B4DE0',
              color: 'white',
              px: 3,
              py: 2,
              borderTopRightRadius: 9,
              borderTopLeftRadius: 9,
              zIndex: 1000,
            }}
          >
            <Avatar sx={{ mr: 1 }} src={logo} />
            <Typography variant="subtitle1">VisionAI</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
              <div style={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: '#6FF34EE4', marginRight: 4 }}></div>
              <Typography variant="caption">Online</Typography>
            </Box>
          </Box>
          <Box sx={{ flex: 1, overflowY: 'auto', p: 2 }}>
            {messages.map((message, index) => (
              <Fade key={index} in={true} timeout={(index + 1) * 500}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: message.sender === 'user' ? 'row-reverse' : 'row',
                    alignItems: 'flex-end',
                    mb: 1,
                  }}
                >
                  <Box
                    sx={{
                      px: 2,
                      py: 1,
                      borderRadius: 2,
                      backgroundColor: message.sender === 'user' ? '#3B4B4DE0' : '#F0F0F0',
                      color: message.sender === 'user' ? 'white' : 'black',
                      maxWidth: '70%',
                      overflowWrap: 'break-word',
                    }}
                  >
                    <Typography variant="body1">{message.text}</Typography>
                  </Box>
                  <Typography
                    variant="caption"
                    sx={{ ml: message.sender === 'user' ? 0 : 1, mr: message.sender === 'user' ? 1 : 0 }}
                  >
                    {message.timestamp}
                  </Typography>
                </Box>
              </Fade>
            ))}
            {loading && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>
          <Box sx={{ p: 2, display: 'flex' }}>
            <TextField
              variant="outlined"
              placeholder="Type your message..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  sendMessage();
                }
              }}
              sx={{ flex: 1 }}
              autoComplete="off"
            />
            <IconButton
              color="primary"
              onClick={sendMessage}
              sx={{
                color: '#3B4B4DE0',
                backgroundColor: 'white',
              }}
            >
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Chatbot;