// pages/AuthPage.js
import React, { useState } from 'react';
import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import logo from '../../assets/logo_white.png';
import ResetPasswordForm from './ResetPasswordForm';


const AuthPage = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [showResetPassword, setShowResetPassword] = useState(false);
  
    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
      setShowResetPassword(false);
    };
  
    const handleResetPassword = () => {
      setShowResetPassword(true);
    };

  return (
    <Container maxWidth="sm"
    style={{
        margin: '0 auto',
    }}>
      <Box mt={8} mb={4}>
      <Link to="https://visionlabs.tech"
      style = {{
        textDecoration: 'none',
        color: 'var(--text-primary)',
      }}
      >
          <img src={logo} alt="X-Ray Vision Logo" 
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '20%',

          }}

          />
        </Link>
        <Link to='https://visionlabs.tech'
        style={{
            textDecoration: 'none',
            color: 'var(--text-primary)',
        }}
        >
            <Typography variant="h1" align="center" gutterBottom
                sx={{
                    fontFamily: 'var(--font-primary)',
                    fontWeight: 700,
                    fontSize: '2.5rem',
                }}
            >
            Visionlabs
            </Typography>
            <Typography variant="h2" align="center" gutterBottom
                sx={{
                    fontFamily: 'var(--font-secondary)',
                    fontWeight: 400,
                    fontSize: '1.5rem',
                }}
            >
            Revolutionizing Healthcare with AI
            </Typography>
        </Link>
        
      </Box>
      <Box
        sx={{
            border: 'none',
            borderRadius: '10px',
            p: 8,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#C2CDBA15',
            backdropFilter: 'blur(10px)',
          }}
      >
        {!showResetPassword ? (
          <>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                centered
                indicatorColor="primary"
                textColor="inherit"
                sx={{
                    '& .MuiTabs-indicator': {
                    backgroundColor: 'white',
                    },
                    '& .MuiTab-root': {
                    color: 'white',
                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    },
                    },
                }}
                >
                <Tab label="Login" />
                <Tab label="Signup" />
                </Tabs>
            {activeTab === 0 && <LoginForm onResetPassword={handleResetPassword} />}
            {activeTab === 1 && <SignupForm />}
          </>
        ) : (
          <>
            <Typography variant="h6" align="center" gutterBottom>
              Reset Password
            </Typography>
            <ResetPasswordForm />
          </>
        )}
      </Box>
    </Container>
  );
};

export default AuthPage;