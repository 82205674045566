// RightSidebar/reducers.js
import {
    UPDATE_GAMMA_CORRECTION_CHECKED,
    UPDATE_NOISE_REDUCTION_CHECKED,
    UPDATE_EDGE_ENHANCEMENT_CHECKED,
    UPDATE_CONTRAST_SLIDER_VALUE,
    UPDATE_BRIGHTNESS_SLIDER_VALUE,
    UPDATE_THRESHOLD_SLIDER_VALUE,
  } from './actions';
  
  const initialState = {
    gammaCorrectionChecked: false,
    noiseReductionChecked: false,
    edgeEnhancementChecked: false,
    contrastSliderValue: 50,
    brightnessSliderValue: 50,
    thresholdSliderValue: 50,
  };
  
  const rightSidebarReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_GAMMA_CORRECTION_CHECKED:
        return {
          ...state,
          gammaCorrectionChecked: action.payload,
        };
      case UPDATE_NOISE_REDUCTION_CHECKED:
        return {
          ...state,
          noiseReductionChecked: action.payload,
        };
      case UPDATE_EDGE_ENHANCEMENT_CHECKED:
        return {
          ...state,
          edgeEnhancementChecked: action.payload,
        };
      case UPDATE_CONTRAST_SLIDER_VALUE:
        return {
          ...state,
          contrastSliderValue: action.payload,
        };
      case UPDATE_BRIGHTNESS_SLIDER_VALUE:
        return {
          ...state,
          brightnessSliderValue: action.payload,
        };
      case UPDATE_THRESHOLD_SLIDER_VALUE:
        return {
          ...state,
          thresholdSliderValue: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default rightSidebarReducer;