import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { togglePDLeftSidebar, togglePDRightSidebar } from '../redux/PathologyDetection/actions';
import PDLeftSidebar from '../components/Page-Components/PathologyDetection/PDLeftSidebar';
import ImageViewer from '../components/Page-Components/AdvancedPreScreening/ImageViewer';
import PDRightSidebar from '../components/Page-Components/PathologyDetection/PDRightSidebar';
import './AdvancedPreScreening.css';
import './PathologyDetection.css';
// import IconButton from '@mui/joy/IconButton';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const PathologyDetection = () => {
  const dispatch = useDispatch();
  const imageViewerRef = useRef();

  const isPDLeftSidebarOpen = useSelector((state) => state.PathologyDetection.isPDLeftSidebarOpen);
  const isPDRightSidebarOpen = useSelector((state) => state.PathologyDetection.isPDRightSidebarOpen);  

  const handleTogglePDLeftSidebar = () => {
    dispatch(togglePDLeftSidebar());
  };

  const handleTogglePDRightSidebar = () => {
    dispatch(togglePDRightSidebar());
  };

  return (
    <div className="page-container">
      {/* <IconButton
        onClick={handleTogglePDLeftSidebar}
        className='left-toggle-button'
        sx={{
          position: 'absolute',
          top: '8.3rem',
          left: 0,
          transform: 'translateY(-50%)',
          zIndex: 99,
          backgroundColor: '#256F7BA8',
          color: 'white',
          borderRadius: 0,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        {isPDLeftSidebarOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
      </IconButton> */}
      {isPDLeftSidebarOpen && (
        <PDLeftSidebar
          className={`left-sidebar ${isPDLeftSidebarOpen ? 'open' : 'closed'}`}
          onToggle={handleTogglePDLeftSidebar}
        />
      )}
      <ImageViewer
        ref={imageViewerRef}
        className={`image-viewer ${isPDLeftSidebarOpen || isPDRightSidebarOpen ? 'expand' : ''}`}
        isPDLeftSidebarOpen={isPDLeftSidebarOpen}
        isPDRightSidebarOpen={isPDRightSidebarOpen}
      />
      {/* <IconButton
        onClick={handleTogglePDRightSidebar}
        className='right-toggle-button'
        sx={{
          position: 'absolute',
          top: '8.3rem',
          right: 0,
          transform: 'translateY(-50%)',
          zIndex: 99,
          backgroundColor: '#256F7BA8',
          color: 'white',
          borderRadius: 0,
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
        }}
      >
        {isPDRightSidebarOpen ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
      </IconButton> */}
      {isPDRightSidebarOpen && (
        <PDRightSidebar
          className={`right-sidebar ${isPDRightSidebarOpen ? 'open' : 'closed'}`}
          onToggle={handleTogglePDRightSidebar}
        />
      )}
    </div>
  );
};

export default PathologyDetection;