export const UPDATE_DETECTED_PATHOLOGIES = 'UPDATE_DETECTED_PATHOLOGIES';
export const UPDATE_PATHOLOGY_PREDICTION_CLICKED = 'UPDATE_PATHOLOGY_PREDICTION_CLICKED';

export const updateDetectedPathologies = (updatedDetectedPathologies) => ({
  type: UPDATE_DETECTED_PATHOLOGIES,
  payload: updatedDetectedPathologies,
});

export const updatePathologyPredictionClicked = () => ({
  type: UPDATE_PATHOLOGY_PREDICTION_CLICKED,
});